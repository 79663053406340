import { VFC } from "react";

import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import FiltersAppBar from "utils/layout/FiltersAppBar";

import { BrokerShippersIndexFilterState } from "../store/brokerShippersIndexFilterSlice";

interface BrokerShippersFilterBarViewProps {
  filters?: JSX.Element;
  searchQuery: string;
  lastRefreshedAt?: string;
  isLoading: boolean;

  onChangeFilter: (changes: Partial<BrokerShippersIndexFilterState>) => void;
  onRefresh: () => void;
}

const BrokerShippersFilterBarView: VFC<BrokerShippersFilterBarViewProps> = ({
  filters,
  searchQuery,
  lastRefreshedAt,
  isLoading,
  onChangeFilter,
  onRefresh,
}) => {
  return (
    <FiltersAppBar className="shadow">
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          <SimpleSearchView
            search={searchQuery}
            onChange={(searchQuery) => onChangeFilter({ searchQuery })}
            placeholder={"Search"}
          />
          {filters}
        </div>
        <div className="whitespace-nowrap flex grow items-center justify-end">
          {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
          <div>
            <RefetchButton loading={isLoading} onClick={onRefresh} />
          </div>
        </div>
      </div>
    </FiltersAppBar>
  );
};

export default BrokerShippersFilterBarView;
