import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

export type BrokerShippersIndexFilterState = {
  searchQuery: string;
};

const initialState: BrokerShippersIndexFilterState = {
  searchQuery: "",
};

const slice = createSlice({
  name: "brokerShippersIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<BrokerShippersIndexFilterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const brokerShippersIndexFilterActions = usableActions(slice.actions);
export default slice;
