import { baseRestApi } from "api/rest/baseRestApi";

import { ApiResponse } from "../types/responses";
import { User } from "./types/User";

type ResponseType = ApiResponse<{ user: User | null }>;

module.hot?.accept();

export const getCurrentUserApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["USER"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getCurrentUser: builder.query<ResponseType, void>({
      providesTags: ["USER"],
      query: () => ({
        method: "GET",
        url: `/users/self`,
      }),
    }),
  }),
});

export const { useGetCurrentUserQuery, useLazyGetCurrentUserQuery } = getCurrentUserApi;
