import { VFC } from "react";

import BrokerInsightsFilterBarContainer from "./containers/BrokerInsightsFilterBarContainer";
import BrokerInsightsIndexTableContainer from "./containers/BrokerInsightsIndexTableContainer";
import { useBrokerInsightsIndexSlices } from "./store/brokerInsightsIndexStore";

const BrokerInsightsIndexPage: VFC = () => {
  useBrokerInsightsIndexSlices();
  return (
    <>
      <BrokerInsightsFilterBarContainer />
      <BrokerInsightsIndexTableContainer />
    </>
  );
};

export default BrokerInsightsIndexPage;
