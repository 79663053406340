import { useHistory, useParams } from "react-router-dom";

import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageParams } from "../../types/QuoteRequestPageParams";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

interface InitialQuoteRequestRouteOptions {
  step: "initial";
}

interface BaseBidRequestRouteOptions {
  step: QuoteRequestPageSteps;
}

export type QuoteRequestRouteOptions = (InitialQuoteRequestRouteOptions | BaseBidRequestRouteOptions) & {
  quoteRequestId?: string | number;
};

const baseRoute = "/shipper/requests/create";

const useChangeQuoteRequestRoute = (): ((options: QuoteRequestRouteOptions) => void) => {
  const params = useParams<QuoteRequestPageParams>();
  const history = useHistory();

  const selectedMode = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.selectedMode);
  const selectMode = quoteRequestActions.useSelectMode();
  const setStep = quoteRequestActions.useSetStep();

  return (options: QuoteRequestRouteOptions): void => {
    const { step } = options;
    const quoteRequestId = options.quoteRequestId || params.quoteRequestId;

    if (step === "initial") {
      selectMode(selectedMode);
      history.push(`${baseRoute}/quotes`);
      return;
    }

    setStep(step);
    history.push(`${baseRoute}/quotes/${quoteRequestId}/${step}`);
  };
};

export default useChangeQuoteRequestRoute;
