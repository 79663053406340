import { useCallback, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, SvgPortexLogo } from "@portex-pro/ui-components";
import { useCompleteClaimMutation } from "api/rest/users/completeClaimApi";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import Loading from "components/Loading";
import PortexLink from "components/PortexLink";
import Text from "components/Text";
import { useUserContext } from "hooks/useUserContext";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

const ClaimComplete: React.VFC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [signupToken] = useQueryParam("signupToken", StringParam);
  const [errorQueryParam] = useQueryParam("error", BooleanParam);
  const auth0 = useAuth0();
  const getCurrentUserQuery = useGetCurrentUserQuery(undefined, { skip: !auth0.isAuthenticated });
  const user = getCurrentUserQuery.data?.data.user;
  const { refreshUserInfo } = useUserContext();

  const buildLogoutReturnToUrl = () => {
    const url = new URL(location.href);
    url.searchParams.set("error", "1");
    return url.href;
  };

  const [completeClaim, completeClaimMutation] = useCompleteClaimMutation();

  const complete = useCallback(async () => {
    if (user?.is_claimed) {
      return;
    }
    try {
      if (!signupToken) {
        throw new Error("No signupToken was provided to complete claim");
      }
      await completeClaim({ body: { signupToken } }).unwrap();
      await refreshUserInfo();
    } catch (e) {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
      Sentry.captureException(e);
      auth0.logout({ returnTo: buildLogoutReturnToUrl() });
    }
  }, [auth0, completeClaim, enqueueSnackbar, user?.is_claimed, signupToken, t, refreshUserInfo]);

  useEffect(() => {
    if (!user || !completeClaimMutation.isUninitialized) {
      return;
    }
    complete();
  }, [complete, completeClaimMutation.isUninitialized, user]);

  if (
    !errorQueryParam &&
    (!user || (user && !user.is_claimed && (completeClaimMutation.isLoading || completeClaimMutation.isUninitialized)))
  ) {
    return (
      <div className="w-full h-full flex flex-col">
        <Loading />
      </div>
    );
  }

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper elevation={24} style={{ maxWidth: "40%" }}>
        <Box p={2.5}>
          <div className="flex justify-center">
            <SvgPortexLogo width={135} height={40} />
          </div>
          {completeClaimMutation.isError || !!errorQueryParam ? (
            <>
              <div className="pt-6 flex justify-center">
                <Text size="large" weight="bold">
                  {t("claimComplete_error_title")}
                </Text>
              </div>
              <div className="pt-4">
                <Text size="medium">{t("claimComplete_error_description")}</Text>
              </div>
            </>
          ) : (
            <>
              <div className="pt-6 flex justify-center">
                <Text size="large" weight="bold">
                  {t("claimComplete_title")}
                </Text>
              </div>
              <div className="pt-4">
                <Text size="medium">{t("claimComplete_description")}</Text>
              </div>
              <div className="py-2 text-brandBlue text-lg font-bold text-center">
                <PortexLink to={{ pathname: "/" }}>{t("bringMeToDashboard")}</PortexLink>
              </div>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ClaimComplete;
