import { ReactElement } from "react";

import useStaticStores from "hooks/store/useStaticStores";
import useLDFlag from "hooks/useLDFlag";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

import Loading from "../../../components/Loading";
import { useUserContext } from "../../../hooks/useUserContext";

const AuthenticatedOrUUTRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, user } = useUserContext();
  const { pathname } = useLocation();
  const uut = useStaticStores((store) => store.authSlice.unclaimedUserToken);
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (!isAuthenticated && !uut) {
    return !!enableBrokerAppBar ? (
      <Redirect to={`/broker/signup?returnTo=${pathname}`} />
    ) : (
      <Redirect to={`/?returnTo=${pathname}`} />
    );
  }

  if (isAuthenticated && !uut && !!user?.shipper) {
    return <Redirect to={`/shipper`} />;
  }

  if (!!enableBrokerAppBar && isAuthenticated && !uut && !user?.is_claimed && !user?.shipper) {
    return <Redirect to={`/broker/signup?returnTo=${pathname}`} />;
  }

  return <Route {...props} />;
};

export default AuthenticatedOrUUTRoute;
