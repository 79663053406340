import { FC } from "react";

import classNames from "classnames";

interface AppBarProps {
  className?: string;
}

const AppBar: FC<AppBarProps> = ({ children, className }) => {
  return (
    <header
      className={classNames(
        "w-full min-h-auto",
        "sticky top-0",
        "border-b-[1px] border-grey-300",
        !className && "bg-white",
        className
      )}
    >
      {children}
    </header>
  );
};

export default AppBar;
