import { VFC } from "react";

import noop from "lodash/noop";

import BrokerInsightsIndexTableView from "../views/BrokerInsightsIndexTableView";

const BrokerInsightsIndexTableContainer: VFC = () => {
  return (
    <BrokerInsightsIndexTableView
      items={[]}
      paginationOptions={{
        next: noop,
        prev: noop,
        page: 0,
        total: 0,
        setTake: noop,
        take: 25,
      }}
      isLoading={false}
    />
  );
};

export default BrokerInsightsIndexTableContainer;
