import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";

import { QuoteRequest } from "../quote-requests";
import { mapQuoteRequestsToShipperTemplates } from "./mapQuoteRequestsToShipperTemplates";
import { ShipperTemplate } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type Body = {
  templateName?: string;
};

type QueryArgs = {
  urlParams: UrlParams;
  body: Body;
};

type ResponseType = ApiResponse<ShipperTemplate>;

module.hot?.accept();

export const createQuoteRequestTemplateApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      createQuoteRequestTemplate: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams, body }) => ({
          method: "POST",
          url: `shipper/quote-requests/${urlParams.quoteRequestId}/template`,
          body,
        }),
        invalidatesTags: ["TEMPLATES"],
        transformResponse: (apiResponse: ApiResponse<{ quoteRequest: QuoteRequest }>) => {
          return { data: mapQuoteRequestsToShipperTemplates([apiResponse.data.quoteRequest])[0] };
        },
      }),
    }),
  });

export const { useCreateQuoteRequestTemplateMutation } = createQuoteRequestTemplateApi;
