import { ShipperQuoteRequest } from "api/rest/quote-requests";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";

import useChangeQuoteRequestRoute from "../../hooks/dispatches/useChangeQuoteRequestRoute";
import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import DrayageRoutingAndDatesView from "../../views/quotes/drayage/DrayageRoutingAndDatesView";

const RoutingStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Routing),
  Component: ({ loadedData: { quoteRequest } }) => {
    const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
    const saveAndChangeRoute = useSaveAndChangeRoute();
    const changeQuoteRequest = quoteRequestActions.useChangeQuoteRequest();
    const { incoterms, routing_type, unloading_details, stops } = useRequestManagementSliceSelector(
      (store) => store.quoteRequestSlice.quoteRequest ?? ({} as ShipperQuoteRequest)
    );

    const isValid =
      !!incoterms &&
      !!routing_type &&
      !!unloading_details !== undefined &&
      !!stops[0]?.address &&
      !!stops[1]?.address &&
      !!stops[0]?.start;

    return (
      <ProgressionButtons
        onBack={() => changeQuoteRequestRoute({ step: "initial" })}
        nextProps={{ disabled: !isValid }}
        onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Shipment })}
      >
        <DrayageRoutingAndDatesView
          quoteRequest={quoteRequest}
          onChangeQuoteRequest={(changes) => changeQuoteRequest({ changes })}
        />
      </ProgressionButtons>
    );
  },
});

export default RoutingStep;
