import { VFC } from "react";

import noop from "lodash/noop";
import { DateTime } from "luxon";

import { brokerBidsIndexFilterActions } from "../store/brokerBidsIndexFilterSlice";
import { useBrokerBidsIndexSliceSelector } from "../store/brokerBidsIndexStore";
import BrokerBidsFilterBarView from "../views/BrokerBidsFilterBarView";

const BrokerBidsFilterBarContainer: VFC = () => {
  const { searchQuery, transportationMode } = useBrokerBidsIndexSliceSelector(
    (store) => store.brokerBidsIndexFilterSlice
  );
  const setFilterState = brokerBidsIndexFilterActions.useSetFilterState();
  const now = Date.now();
  const lastRefreshedAt = DateTime.fromMillis(now).toLocaleString(DateTime.TIME_WITH_SECONDS);

  return (
    <BrokerBidsFilterBarView
      isLoading={false}
      onChangeFilter={setFilterState}
      onRefresh={noop}
      searchQuery={searchQuery}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default BrokerBidsFilterBarContainer;
