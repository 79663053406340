import { VFC } from "react";

import { LoadsIndexFilterState } from "api/rest/loads/types";
import { ShipmentState } from "app/pages/shipments/types/domain";
import DropdownFilterView from "components/DropdownFilterView";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import xor from "lodash/xor";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";
import FiltersAppBar from "utils/layout/FiltersAppBar";

interface LoadsFilterBarViewProps {
  filters?: JSX.Element;
  transportationMode: TransportationMode;
  shipmentStates: ShipmentState[];
  searchQuery: string;
  lastRefreshedAt?: string;
  isLoading: boolean;

  onChangeFilter: (changes: Partial<LoadsIndexFilterState>) => void;
  onRefresh: () => void;

  disableIntermodal?: boolean;
  disableDrayage?: boolean;
}

const LoadsFilterBarView: VFC<LoadsFilterBarViewProps> = (props) => {
  const {
    filters,
    transportationMode,
    shipmentStates,
    searchQuery,
    lastRefreshedAt,
    isLoading,
    onChangeFilter,
    onRefresh,
    disableIntermodal = false,
    disableDrayage = false,
  } = props;

  const { t } = useTranslation("shipments");

  const stateFilterItems = [
    {
      label: t("status_created"),
      value: ShipmentState.Created,
    },
    {
      label: t("status_inTransit"),
      value: ShipmentState.InTransit,
    },
    {
      label: t("status_delivered"),
      value: ShipmentState.Delivered,
    },
    {
      label: t("status_canceled"),
      value: ShipmentState.Canceled,
    },
  ];

  return (
    <FiltersAppBar>
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          <ModeButtonGroup
            value={transportationMode}
            onChange={(transportationMode) => onChangeFilter({ transportationMode })}
            includeModes={[TransportationMode.INTERMODAL]}
            disabledModes={[
              TransportationMode.Air,
              TransportationMode.Ocean,
              TransportationMode.Trucking,
              TransportationMode.INTERMODAL,
              TransportationMode.DRAYAGE,
            ].filter((value) => {
              if (!disableIntermodal && value === "INTERMODAL") {
                return false;
              }
              if (!disableDrayage && value === "DRAYAGE") {
                return false;
              }
              return true;
            })}
          />
          <DropdownFilterView
            placeholder={t("statusFilter_placeholder")}
            selected={shipmentStates}
            options={stateFilterItems}
            onChange={(state) => onChangeFilter({ shipmentStates: xor(shipmentStates, [state]) })}
            minWidth={200}
          />
          <SimpleSearchView
            search={searchQuery}
            onChange={(searchQuery) => onChangeFilter({ searchQuery })}
            placeholder={t("searchShipments_placeholder")}
          />
          {filters}
        </div>
        <div className="whitespace-nowrap flex grow items-center justify-end">
          {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
          <div>
            <RefetchButton loading={isLoading} onClick={onRefresh} tooltip={t("refetchShipments_button")} />
          </div>
        </div>
      </div>
    </FiltersAppBar>
  );
};

export default LoadsFilterBarView;
