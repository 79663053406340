import { ComponentProps, useEffect, useState, VFC } from "react";

import { Add } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { useBoolean } from "usehooks-ts";

import {
  use__DEPRECATED__getQuoteRequestTemplatesQuery,
  use__DEPRECATED__deleteQuoteRequestTemplateMutation,
  use__DEPRECATED__updateQuoteRequestTemplateMutation,
} from "../../api/rest/quote-request-templates";
import EditTemplatesTableView from "./EditTemplatesTableView";
import { useAddTemplateToDelete, useAddTemplateToUpdate, useResetState } from "./store/editTemplatesSlice";
import {
  useQuoteRequestTemplatesSlices,
  useQuoteRequestTemplatesSliceSelector,
} from "./store/quoteRequestTemplatesStore";
import TemplatesTableView from "./TemplatesTableView";

interface QuoteRequestTemplateListProps {
  mode: "FTL" | "LTL" | "FCL" | "AIR";
  onClickCreateQuoteRequest?: () => void;
  onClickTemplate?: (templateId: number) => void;
}

/** @deprecated Instead, use `QuoteRequestTemplates` */
const QuoteRequestTemplateList: VFC<QuoteRequestTemplateListProps> = ({
  mode,
  onClickCreateQuoteRequest,
  onClickTemplate,
}) => {
  useQuoteRequestTemplatesSlices();
  const templatesToDelete = useQuoteRequestTemplatesSliceSelector(
    (state) => state.editTemplatesSlice.templatesToDelete
  );
  const templatesToUpdate = useQuoteRequestTemplatesSliceSelector(
    (state) => state.editTemplatesSlice.templatesToUpdate
  );
  const resetState = useResetState();
  const addTemplateToDelete = useAddTemplateToDelete();
  const addTemplateToUpdate = useAddTemplateToUpdate();
  const { t } = useTranslation("quoteRequestTemplates");
  const { enqueueSnackbar } = useSnackbar();
  const editingTemplates = useBoolean(false);
  const [search, setSearch] = useState<string | undefined>();

  const [deleteTemplate, { isLoading: isDeleting }] = use__DEPRECATED__deleteQuoteRequestTemplateMutation();
  const [updateTemplate, { isLoading: isUpdating }] = use__DEPRECATED__updateQuoteRequestTemplateMutation();

  const templatesQuery = use__DEPRECATED__getQuoteRequestTemplatesQuery({
    queryParams: {
      mode,
      search,
    },
  });
  const templates = templatesQuery.data?.data.templates ?? [];

  const handleSaveTemplates: ComponentProps<typeof EditTemplatesTableView>["onSave"] = async () => {
    try {
      await Promise.all(
        templatesToUpdate.map(async (payload) => {
          await updateTemplate({ urlParams: { templateId: payload.id }, body: payload.body || {} }).unwrap();
        })
      );
      await Promise.all(
        templatesToDelete.map(async (payload) => {
          await deleteTemplate({ urlParams: { templateId: payload.id } }).unwrap();
        })
      );
      enqueueSnackbar(t("saveTemplatesSuccess"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("saveTemplatesError"), { variant: "error" });
      Sentry.captureException(e);
    } finally {
      editingTemplates.setFalse();
    }
  };

  useEffect(() => {
    if (templatesQuery.isUninitialized || templatesQuery.isLoading || templatesQuery.isFetching || !!search) {
      return;
    }

    if (templates.length === 0) {
      onClickCreateQuoteRequest?.();
    }
  }, [
    onClickCreateQuoteRequest,
    search,
    templates.length,
    templatesQuery.isFetching,
    templatesQuery.isLoading,
    templatesQuery.isUninitialized,
  ]);

  useEffect(() => {
    if (editingTemplates.value === false) {
      resetState();
    }
  }, [editingTemplates.value, resetState]);

  return (
    <>
      <div className="z-[99] sticky top-0 bg-white p-3 flex justify-between">
        <ThrottledTextInput
          margin="none"
          fullWidth={false}
          placeholder={t("searchPlaceholder")}
          search
          value={search ?? ""}
          onChange={setSearch}
          onClear={() => setSearch("")}
        />

        {!!editingTemplates.value && (
          <div className="flex items-center">
            <Text size="medium">{t("editTemplatesInstructions")}</Text>
          </div>
        )}
        {!editingTemplates.value && (
          <div className="flex gap-3">
            <Button variant="outlined" color="primary" style={{ minWidth: 160 }} onClick={editingTemplates.setTrue}>
              {t("editTemplatesButton")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => onClickCreateQuoteRequest?.()}
            >
              {t("createQuoteRequestButton")}
            </Button>
          </div>
        )}
      </div>
      {!!editingTemplates.value ? (
        <EditTemplatesTableView
          items={templates
            .filter((item) => {
              const isDeleted = !!templatesToDelete.find(({ id }) => id === item.id);
              return !isDeleted;
            })
            .map((item) => {
              const payload = templatesToUpdate.find(({ id }) => id === item.id);
              return {
                ...item,
                ...payload?.body,
              };
            })}
          isEditing={editingTemplates.value}
          loading={templatesQuery.isFetching || isDeleting || isUpdating}
          onSave={handleSaveTemplates}
          onCancel={editingTemplates.setFalse}
          onChangeTemplate={addTemplateToUpdate}
          onClickDeleteTemplate={addTemplateToDelete}
        />
      ) : (
        <TemplatesTableView items={templates} onClickTemplate={onClickTemplate} loading={templatesQuery.isLoading} />
      )}
    </>
  );
};

export default QuoteRequestTemplateList;
