import { ReactElement, useMemo, useState } from "react";

import {
  SidebarUser,
  makeStyles,
  createStyles,
  ButtonBase,
  ButtonBaseProps,
  Menu,
  MenuItem,
  MenuList,
  useLayoutContext,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useUserContext } from "../hooks/useUserContext";
import { useVersionStore } from "../hooks/useVersionStore";
import { getUserInitials, getUserFullName, getUserCompany } from "../utils/user";

const ButtonWithoutRipple = (props: ButtonBaseProps): ReactElement => {
  return <ButtonBase {...props} disableRipple />;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

const SidebarProfile = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    sidebar: { isCollapsed, collapsedWidth, width },
  } = useLayoutContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { auth0User, user } = useUserContext();
  const versionStore = useVersionStore();

  const initials = useMemo(() => {
    return getUserInitials({ user, auth0User });
  }, [user, auth0User]);

  const name = useMemo(() => {
    return getUserFullName({ user, auth0User });
  }, [user, auth0User]);

  const company = useMemo(() => {
    return getUserCompany({ user });
  }, [user]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginTop: "auto" }} className={classes.root}>
      <SidebarUser
        component={ButtonWithoutRipple}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        style={{ cursor: "pointer", userSelect: "none", paddingLeft: "11px" }}
        avatar={<strong>{initials}</strong>}
        name={isCollapsed ? "" : name}
        company={isCollapsed ? "" : company}
        width="100%"
      />
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 210,
          horizontal: isCollapsed ? -collapsedWidth : -width,
        }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{ elevation: 24, style: { zIndex: 1, border: "none", margin: "0" } }}
      >
        <MenuList id="menu-list-grow" style={{ minWidth: 180, zIndex: 0 }}>
          {user?.portex_admin === true ? (
            <MenuItem component={Link} to={"/admin"} onClick={handleClose}>
              {t("sidebarProfile.adminPortal")}
            </MenuItem>
          ) : null}
          <MenuItem component="a" href="mailto:support@portexpro.com" onClick={handleClose}>
            {t("sidebarProfile.help")}
          </MenuItem>
          <MenuItem component={Link} to={"/logout"} onClick={handleClose}>
            {t("sidebarProfile.logout")}
          </MenuItem>
          <MenuItem
            component={Link}
            to={{ pathname: "/version" }}
            style={{ opacity: 0.5 }}
          >{`v${versionStore.versionLocal}`}</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default SidebarProfile;
