import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BrokerQuoteRequest, BrokerQuoteResponse, LineItemType } from "api/rest/quote-requests";
import { getBrokerQuoteRequestApi } from "api/rest/quote-requests/getBrokerQuoteRequest";
import usableActions from "utils/store/usableActions";

export type LineItem = {
  amount?: number;
} & (
  | {
      type: Extract<LineItemType, "FREIGHT_CHARGE">;
      cargoGroup: BrokerQuoteRequest["cargo_groups"][number];
    }
  | {
      type: Extract<LineItemType, "ADDITIONAL_CHARGE">;
    }
);

export type State = {
  lineItems: Array<LineItem>;
  quote: Partial<BrokerQuoteResponse>;
};

const initialState: State = {
  lineItems: [],
  quote: {
    mode: "DRAYAGE",
  },
};

const brokerQuoteSubmissionSlice = createSlice({
  name: "brokerQuoteSubmissionSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuote: (state, action: PayloadAction<Partial<State["quote"]>>) => {
      state.quote = { ...state.quote, ...action.payload };
    },
    setLineItems: (
      state,
      action: PayloadAction<{ index: number; changes: Partial<Omit<LineItem, "cargoGroup" | "type">> }>
    ) => {
      const { index, changes } = action.payload;

      state.lineItems[index] = { ...state.lineItems[index], ...changes };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getBrokerQuoteRequestApi.endpoints.getBrokerQuoteRequest.matchFulfilled, (state, action) => {
      const cargoGroups = action.payload.data.quoteRequest.cargo_groups;

      const newLineItems: State["lineItems"] = [];

      cargoGroups.forEach((cargoGroup) => {
        newLineItems.push({ type: "FREIGHT_CHARGE", cargoGroup: cargoGroup });
      });

      newLineItems.push({ type: "ADDITIONAL_CHARGE" });

      state.lineItems = newLineItems;
    });
  },
});

export const { useSetQuote, useResetState, useSetLineItems } = usableActions(brokerQuoteSubmissionSlice.actions);
export default brokerQuoteSubmissionSlice;
