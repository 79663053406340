import { FC } from "react";

import classNames from "classnames";
import AppBar from "components/AppBar";

interface FiltersAppBarProps {
  className?: string;
}

const FiltersAppBar: FC<FiltersAppBarProps> = ({ children, className }) => {
  return <AppBar className={classNames("px-4 py-2 bg-white", className)}>{children}</AppBar>;
};

export default FiltersAppBar;
