import { baseRestApi } from "api/rest/baseRestApi";
import { PaginatedApiRequestQueryArgs, SearchQueryParamsBase } from "api/rest/types/requests";
import { ApiResponse, ApiResponsePaginatedCursor } from "api/rest/types/responses";
import { ModeQuotes } from "types/Mode";
import enhanceWithPagination from "utils/enhanceWithPagination";

import { QuoteRequest } from "../quote-requests";
import { mapQuoteRequestsToShipperTemplates } from "./mapQuoteRequestsToShipperTemplates";
import { ShipperTemplate } from "./types";

type QueryParams = SearchQueryParamsBase & {
  mode: ModeQuotes;
};

type QueryArgs = {
  queryParams: QueryParams;
};

type ResponseType = ApiResponse<{ templates: ShipperTemplate[] }>;

module.hot?.accept();

export const getQuoteRequestTemplatesApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      __DEPRECATED__getQuoteRequestTemplates: builder.query<ResponseType, QueryArgs>({
        query: ({ queryParams }) => ({
          method: "GET",
          url: `shipper/quote-requests/templates`,
          params: queryParams,
        }),
        providesTags: ["TEMPLATES"],
      }),
      getQuoteRequestTemplates: builder.query<
        ApiResponsePaginatedCursor<ResponseType["data"]>,
        PaginatedApiRequestQueryArgs<QueryArgs>
      >({
        query: ({ queryParams }) => ({
          method: "GET",
          url: `shipper/quote-requests`,
          params: {
            ...queryParams,
            status: "TEMPLATE",
          },
        }),
        providesTags: ["TEMPLATES"],
        transformResponse: (apiResponse: ApiResponsePaginatedCursor<{ quoteRequests: QuoteRequest[] }>) => {
          return {
            data: { templates: mapQuoteRequestsToShipperTemplates(apiResponse.data.quoteRequests) },
            cursor: apiResponse.cursor,
            total: apiResponse.total,
          };
        },
      }),
    }),
  });

export const { use__DEPRECATED__getQuoteRequestTemplatesQuery } = getQuoteRequestTemplatesApi;
export const { useGetQuoteRequestTemplatesQuery } = enhanceWithPagination(
  getQuoteRequestTemplatesApi,
  "getQuoteRequestTemplates",
  { take: 25 }
);
