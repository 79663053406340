import { baseRestApi } from "api/rest/baseRestApi";

module.hot?.accept();

type BodyParams = {
  email: string;
};

type QueryArgs = {
  body: BodyParams;
};

const requestClaimApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    requestClaim: builder.mutation<void, QueryArgs>({
      query: (params) => ({
        method: "POST",
        url: `/users/claim/request`,
        body: params.body,
      }),
    }),
  }),
});

export default requestClaimApi;

export const { useRequestClaimMutation } = requestClaimApi;
