import { VFC } from "react";

import noop from "lodash/noop";

import BrokerShippersIndexTableView from "../views/BrokerShippersIndexTableView";

const BrokerShippersIndexTableContainer: VFC = () => {
  return (
    <BrokerShippersIndexTableView
      items={[]}
      paginationOptions={{
        next: noop,
        prev: noop,
        page: 0,
        total: 0,
        setTake: noop,
        take: 25,
      }}
      isLoading={false}
    />
  );
};

export default BrokerShippersIndexTableContainer;
