import { VFC } from "react";

import BrokerShippersFilterBarContainer from "./containers/BrokerShippersFilterBarContainer";
import BrokerShippersIndexTableContainer from "./containers/BrokerShippersIndexTableContainer";
import { useBrokerShippersIndexSlices } from "./store/brokerShippersIndexStore";

const BrokerShippersIndexPage: VFC = () => {
  useBrokerShippersIndexSlices();
  return (
    <>
      <BrokerShippersFilterBarContainer />
      <BrokerShippersIndexTableContainer />
    </>
  );
};

export default BrokerShippersIndexPage;
