import { FC } from "react";

import useStaticStores from "hooks/store/useStaticStores";
import { useUserContext } from "hooks/useUserContext";
import compact from "lodash/compact";
import { StringParam, useQueryParam } from "use-query-params";

type LoggedInAs = "shipper" | "broker" | "claimed" | "unclaimed" | "uut";

interface LoggedInProps {
  as?: LoggedInAs | LoggedInAs[];
}

const LoggedIn: FC<LoggedInProps> = ({ children, ...props }) => {
  const { isLoading, user, auth0User } = useUserContext();
  const unclaimedUserToken = useStaticStores((store) => store.authSlice.unclaimedUserToken);
  const [uut] = useQueryParam("uut", StringParam);
  const loggedInAs = compact([props.as].flat());

  if (!children) {
    return null;
  }
  if (isLoading) {
    return null;
  }
  if (!user) {
    return null;
  }
  if (!auth0User) {
    return null;
  }

  if (loggedInAs.includes("shipper") && !user.shipper) {
    return null;
  }

  if (loggedInAs.includes("broker") && !!user.shipper) {
    return null;
  }

  if (loggedInAs.includes("claimed") && !user.is_claimed) {
    return null;
  }

  if (loggedInAs.includes("unclaimed") && !!user.is_claimed) {
    return null;
  }

  if (loggedInAs.includes("uut") && !unclaimedUserToken && !uut) {
    return null;
  }

  return <>{children}</>;
};

export default LoggedIn;
