import { Response, useGetLaneBenchmarkQuery } from "api/rest/lane-benchmark/getLaneBenchmarkApi";
import { BenchmarkLaneArg } from "api/rest/lane-benchmark/types";
import LaneBenchmarkView from "components/lane-benchmark/LaneBenchmarkView";
import { withRequired } from "components/withRequired";
import useLDFlag from "hooks/useLDFlag";
import { ModeQuotes } from "types/Mode";

type LaneBenchmarkProps = {
  quoteRequest:
    | {
        id: number | string;
        mode: ModeQuotes | "LCL";
        stops: {
          position: number;
          address?: {
            city?: string | null | undefined;
            province_code?: string | null | undefined;
            province_name?: string | null | undefined;
          } | null;
        }[];
      }
    | undefined
    | null;
};

const LaneBenchmark = withRequired<LaneBenchmarkProps, "quoteRequest">({
  requiredKeys: ["quoteRequest"],
  Component: ({ quoteRequest }) => {
    const quoteRequestId = Number(quoteRequest.id);
    const mode = quoteRequest.mode;
    const lane: BenchmarkLaneArg = quoteRequest.stops.map((stop) => ({
      city: stop.address?.city || undefined,
      province: stop.address?.province_code || stop.address?.province_name || undefined,
      position: stop.position,
    }));
    const laneString = lane.map((stop) => [stop.city, stop.province].filter(Boolean).join(", ")).join(" to ");
    const isEnabled = useLDFlag("enableLaneBenchmark");

    const { data, isLoading, isError, isUninitialized } = useGetLaneBenchmarkQuery(
      {
        queryParams: { quoteRequestId, mode, lane },
      },
      { skip: !isEnabled }
    );

    let props:
      | {
          laneBenchmark: Response["data"];
          isLoading: false;
        }
      | {
          laneBenchmark: undefined;
          isLoading: true;
        } = {
      isLoading: true,
      laneBenchmark: undefined,
    };

    if (!isLoading && !isError && !isUninitialized) {
      props = {
        isLoading: false,
        laneBenchmark: data.data,
      };
    }

    if (!isEnabled) {
      return null;
    }

    return <LaneBenchmarkView {...props} lane={laneString} />;
  },
});

export default LaneBenchmark;
