import { EM_DASH } from "constants/index";

import formatAddress from "app/pages/shipments/utils/formatAddress";
import formatContact from "app/pages/shipments/utils/formatContact";
import LoadTypeChip from "components/LoadTypeChip";
import Text from "components/Text";
import withAsync from "components/withAsync";
import { getCargoGroupEquipmentString } from "features/cargo-groups/utils/getCargoGroupEquipmentString";
import ContainerCargoGroupsOverviewView from "features/cargo-groups/views/ContainerCargoGroupsOverviewView";
import first from "lodash/first";
import last from "lodash/last";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import useLoadQuoteRequestAndQuote from "../../hooks/quotes/useLoadQuoteRequestAndQuote";

const QuoteDetailsContainer = withAsync({
  useHook: useLoadQuoteRequestAndQuote,
  Component: ({ loadedData: { quote, quoteRequest } }) => {
    const { t } = useTranslation(["shipper", "common", "shipments", "quoteDetails"]);

    const pickup = first(quoteRequest.stops);
    const delivery = last(quoteRequest.stops);

    const pickupContact = first(pickup?.address?.address_contacts);
    const deliveryContact = first(delivery?.address?.address_contacts);

    return (
      <div className="w-full pb-10">
        {/* card start */}
        <div className="border rounded w-full bg-white">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:quoteDetails")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:totalAmount")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{formatUSD(quote.total_amount)}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full whitespace-pre-wrap">
              <Text size="medium">{quote.booking_notes ?? EM_DASH}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:notes")}
              </Text>
            </div>
            <div className="w-full whitespace-pre-wrap">
              <Text size="medium">{quote.notes ?? EM_DASH}</Text>
            </div>
          </div>

          {/* card row */}
          <div className="bg-grey-100 w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
                {t("common:freightCharges")}
              </Text>
            </div>
            <div className="w-full whitespace-pre-wrap">
              <Text size="medium">
                {quote.mode === "DRAYAGE"
                  ? formatUSD(quote.line_items.reduce((total, lineItem) => total + lineItem.amount, 0))
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {quote.mode === "DRAYAGE" &&
            quote.line_items
              .filter((lineItem) => lineItem.type === "FREIGHT_CHARGE" && !!lineItem.cargo_group)
              .map((lineItem) => (
                <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
                  <div className="w-1/3 min-w-60 text-nowrap">
                    <Text size="medium" weight="bold">
                      {lineItem.cargo_group ? getCargoGroupEquipmentString(lineItem.cargo_group, t) : EM_DASH}
                    </Text>
                  </div>
                  <div className="w-full whitespace-pre-wrap">
                    <Text size="medium">{formatUSD(lineItem.amount)}</Text>
                  </div>
                </div>
              ))}

          {quote.mode === "DRAYAGE" &&
            quote.line_items
              .filter((lineItem) => lineItem.type === "ADDITIONAL_CHARGE")
              .map((lineItem) => (
                <div className="bg-grey-100 w-full flex flex-row items-center py-3 px-4">
                  <div className="w-1/3 min-w-60 text-nowrap">
                    <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
                      {t("common:additional")}
                    </Text>
                  </div>
                  <div className="w-full whitespace-pre-wrap">
                    <Text size="medium">{formatUSD(lineItem.amount)}</Text>
                  </div>
                </div>
              ))}

          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:totalAmount")}
              </Text>
            </div>
            <div className="w-full whitespace-pre-wrap">
              <Text size="medium">{formatUSD(quote.total_amount)}</Text>
            </div>
          </div>
        </div>

        {/* card start */}
        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:requestDetails")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:mode")}
              </Text>
            </div>
            <div className="w-full">
              <LoadTypeChip mode={quoteRequest.mode} />
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:equipmentType")}
              </Text>
            </div>
            <div className="w-full">
              {quoteRequest.cargo_groups.map((cargoGroup) => (
                <>
                  {getCargoGroupEquipmentString(cargoGroup, t)} <br />
                </>
              ))}
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:incoterms")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.incoterms ?? EM_DASH}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:hazardousCommodities")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.is_hazardous ? t("common:yes") : t("common:no")}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:customs")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.include_customs_clearance ? t("common:required") : t("common:notRequired")}
              </Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:insurance")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.insurance_required ? t("common:required") : t("common:notRequired")}
              </Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:unloadingDetails")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.unloading_details
                  ? t(`common:unloadingDetails_map.${quoteRequest.unloading_details}`)
                  : EM_DASH}
              </Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:pickup")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:location")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].address
                  ? formatAddress.fromSnakeCase(
                      // @ts-expect-error We can circle around to fix these type issues
                      Object.entries(quoteRequest.stops[0].address ?? {}).reduce((add, [key, value]) => {
                        // @ts-expect-error same as above
                        add[key] = value ?? null;
                        return add;
                      }, {}),
                      "long"
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:addresses.manualName")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[0].address?.name ?? EM_DASH}</Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipments:shipmentDetails_dateAndTime_label")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].start
                  ? DateTime.fromISO(quoteRequest.stops[0].start).toLocaleString(DateTime.DATETIME_FULL)
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:operationsContact")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {pickupContact
                  ? formatContact({
                      email: pickupContact.email ?? "",
                      firstName: pickupContact.first_name ?? "",
                      lastName: pickupContact.last_name ?? "",
                      phoneNumber: pickupContact.phone_number ?? "",
                    })
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[0].address?.booking_notes || EM_DASH}</Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:delivery")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:location")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].address
                  ? formatAddress.fromSnakeCase(
                      // @ts-expect-error We can circle around to fix these type issues
                      Object.entries(quoteRequest.stops[quoteRequest.stops.length - 1].address ?? {}).reduce(
                        (add, [key, value]) => {
                          // @ts-expect-error same as above
                          add[key] = value ?? null;
                          return add;
                        },
                        {}
                      ),
                      "long"
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:addresses.manualName")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[quoteRequest.stops.length - 1].address?.name ?? EM_DASH}</Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipments:shipmentDetails_dateAndTime_label")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].start
                  ? // @ts-expect-error this type error is fine for now
                    DateTime.fromISO(quoteRequest.stops[quoteRequest.stops.length - 1].start).toLocaleString(
                      DateTime.DATETIME_FULL
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:operationsContact")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {deliveryContact
                  ? formatContact({
                      email: deliveryContact.email ?? "",
                      firstName: deliveryContact.first_name ?? "",
                      lastName: deliveryContact.last_name ?? "",
                      phoneNumber: deliveryContact.phone_number ?? "",
                    })
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].address?.booking_notes || EM_DASH}
              </Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:containers")}
            </Text>
          </div>

          {/* card row */}
          <div className="w-full flex flex-row items-center py-3 px-4">
            <ContainerCargoGroupsOverviewView cargoGroups={quoteRequest.cargo_groups} />
          </div>
        </div>
      </div>
    );
  },
});

export default QuoteDetailsContainer;
