import { ComponentType } from "react";

import BrokerLayout from "./BrokerLayout";
import { withTitle, withTitleProps } from "./withTitle";

export const withBrokerLayout = (component: ComponentType<{}>, htmlTitleProps?: withTitleProps): JSX.Element => {
  const Component = component;
  const content = htmlTitleProps ? withTitle(component, htmlTitleProps) : <Component />;

  return <BrokerLayout>{content}</BrokerLayout>;
};
