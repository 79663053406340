import { VFC } from "react";

import noop from "lodash/noop";

import BrokerRequestsIndexTableView from "../views/BrokerRequestsIndexTableView";

const BrokerRequestsIndexTableContainer: VFC = () => {
  return (
    <BrokerRequestsIndexTableView
      items={[]}
      paginationOptions={{
        next: noop,
        prev: noop,
        page: 0,
        total: 0,
        setTake: noop,
        take: 25,
      }}
      isLoading={false}
    />
  );
};

export default BrokerRequestsIndexTableContainer;
