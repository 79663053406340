import { VFC } from "react";

import { AppBar, Toolbar, styled } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import useLDFlag from "hooks/useLDFlag";
import Breadcrumb from "pages/shipper/components/Breadcrumb";
import { useTranslation } from "react-i18next";

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${theme.palette.grey[300]}`,
  height: TOOLBAR_HEIGHT_PX,
}));

const BrokerLoadsAppBarContainer: VFC = () => {
  const { t } = useTranslation("loadsIndex");
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  if (enableBrokerAppBar) {
    return null;
  }

  return (
    <AppBar>
      <StyledToolbar variant="dense" disableGutters>
        <BreadcrumbsContainer>
          <Breadcrumb active>{t("shipments")}</Breadcrumb>
        </BreadcrumbsContainer>
      </StyledToolbar>
    </AppBar>
  );
};

export default BrokerLoadsAppBarContainer;
