import { VFC } from "react";

import { Button, ButtonGroup, TextInput } from "@portex-pro/ui-components";
import { ShipperAddress } from "api/rest/address/types";
import { ShipperQuoteRequest } from "api/rest/quote-requests";
import classNames from "classnames";
import LocationPicker from "components/addresses/LocationPicker";
import DateTimePickerView from "components/datetime/DateTimePickerView";
import { DateUnits } from "components/datetime/types";
import Text from "components/Text";
import { DateTime } from "luxon";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";
import { AddressOption } from "types/AddressOption";
import { IncotermsEnum } from "types/Incoterms";

interface DrayageRoutingAndDatesViewProps {
  quoteRequest: ShipperQuoteRequest;
  onChangeQuoteRequest: (changes: Partial<ShipperQuoteRequest>) => void;
}

const DrayageRoutingAndDatesView: VFC<DrayageRoutingAndDatesViewProps> = (props) => {
  const { onChangeQuoteRequest, quoteRequest } = props;
  const { t } = useTranslation(["common", "requestManagement"]);

  return (
    <div className="w-full h-full">
      <div className="w-full grid grid-cols-4 px-4 gap-x-4 gap-y-8 [&_>_:not(.subgrid)]:flex [&_>_:not(.subgrid)]:flex-col [&_>_:not(.subgrid)]:space-y-3">
        <div className="col-span-4 items-center p-10">
          <Text size="x-large" weight="bold">
            {t("requestManagement:routingAndDates")}
          </Text>
          <Text size="medium">{t("requestManagement:routingAndDates_description")}</Text>
        </div>
        <div className="subgrid grid grid-cols-subgrid col-span-4 pb-3 border-b [&_>_:not(.subgrid)]:flex [&_>_:not(.subgrid)]:flex-col [&_>_:not(.subgrid)]:space-y-3">
          <div>
            <Text size="medium" weight="bold" required>
              {t("common:incoterms")}
            </Text>
            <FormSelectView
              items={Object.values(IncotermsEnum)}
              getItemCopy={(val) => t(`common:incoterms_map.${val}`)}
              value={quoteRequest.incoterms ?? undefined}
              fullWidth
              onChange={(incoterms) => onChangeQuoteRequest({ incoterms })}
            />
          </div>
          <div>
            <Text size="medium" weight="bold" required>
              {t("requestManagement:selectRouting")}
            </Text>
            <ButtonGroup fullWidth>
              <Button
                color="primary"
                className={classNames({ "Por-selected": quoteRequest.routing_type === "P2D" })}
                onClick={() => onChangeQuoteRequest({ routing_type: "P2D" })}
              >
                {t("requestManagement:portToDoor")}
              </Button>
              <Button
                color="primary"
                className={classNames({ "Por-selected": quoteRequest.routing_type === "D2P" })}
                onClick={() => onChangeQuoteRequest({ routing_type: "D2P" })}
              >
                {t("requestManagement:doorToPort")}
              </Button>
            </ButtonGroup>
          </div>
          <div className="col-span-2">
            <Text size="medium" weight="bold" required>
              {t("requestManagement:unloadingDetails")}
            </Text>
            <ButtonGroup fullWidth>
              <Button
                color="primary"
                className={classNames({ "Por-selected": quoteRequest.unloading_details === "LIVE" })}
                onClick={() => onChangeQuoteRequest({ unloading_details: "LIVE" })}
              >
                {t("requestManagement:liveUnload")}
              </Button>
              <Button
                color="primary"
                className={classNames({ "Por-selected": quoteRequest.unloading_details === "DROP" })}
                onClick={() => onChangeQuoteRequest({ unloading_details: "DROP" })}
              >
                {t("requestManagement:dropAndPick")}
              </Button>
              <Button
                color="primary"
                className={classNames({ "Por-selected": quoteRequest.unloading_details === null })}
                onClick={() => onChangeQuoteRequest({ unloading_details: null })}
              >
                {t("requestManagement:n/a")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {quoteRequest.routing_type && (
          <>
            <div className="col-span-2">
              <div>
                <LocationPicker
                  // @todo create a mapper for this address type
                  value={(quoteRequest.stops?.[0]?.address as unknown as AddressOption) ?? undefined}
                  mode={"DRAYAGE"}
                  label={quoteRequest.routing_type === "D2P" ? t("common:pickupAddress") : t("common:portOfLoading")}
                  required
                  toDoor={quoteRequest.routing_type === "D2P"}
                  onChange={(address) =>
                    onChangeQuoteRequest({
                      stops: [
                        // @todo create a mapper for this address type
                        { ...quoteRequest.stops[0], address: address as unknown as ShipperAddress },
                        quoteRequest.stops[1],
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-2">
              <div>
                <LocationPicker
                  // @todo create a mapper for this address type
                  value={(quoteRequest.stops?.[1]?.address as unknown as AddressOption) ?? undefined}
                  mode={"DRAYAGE"}
                  label={
                    quoteRequest.routing_type === "P2D" ? t("common:destinationAddress") : t("common:portOfUnloading")
                  }
                  startIconPallete="red"
                  required
                  toDoor={quoteRequest.routing_type === "P2D"}
                  onChange={(address) =>
                    onChangeQuoteRequest({
                      stops: [
                        quoteRequest.stops[0],
                        // @todo create a mapper for this address type
                        { ...quoteRequest.stops[1], address: address as unknown as ShipperAddress },
                      ],
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-2">
              <Text size="medium" weight="bold" required>
                {t("requestManagement:cargoReadyDate")}
              </Text>
              <DateTimePickerView
                date={
                  quoteRequest.stops?.[0]?.start
                    ? // @todo I'm not sure this cast is safe yet
                      (DateTime.fromISO(quoteRequest.stops[0].start, {
                        zone: quoteRequest.stops?.[0]?.address?.iana_timezone ?? "UTC",
                      }).toObject() as DateUnits)
                    : null
                }
                variant="date"
                onChangeDate={(cargoReadyDate) =>
                  onChangeQuoteRequest({
                    stops: [
                      {
                        ...quoteRequest.stops[0],
                        start: cargoReadyDate
                          ? DateTime.fromObject(cargoReadyDate)
                              .setZone(quoteRequest.stops?.[0]?.address?.iana_timezone ?? "UTC", {
                                keepLocalTime: true,
                              })
                              .toISO()
                          : null,
                      },
                      quoteRequest.stops[1],
                    ],
                  })
                }
              />
            </div>
            <div className="col-span-2">
              <Text size="medium" weight="bold">
                {t("requestManagement:targetDestinationDeliveryDate")}
              </Text>
              <DateTimePickerView
                date={
                  quoteRequest.stops?.[1]?.start
                    ? // @todo I'm not sure this cast is safe yet
                      (DateTime.fromISO(quoteRequest.stops[1].start, {
                        zone: quoteRequest.stops?.[1]?.address?.iana_timezone ?? "UTC",
                      }).toObject() as DateUnits)
                    : null
                }
                variant="date"
                onChangeDate={(targetDeliveryDate) =>
                  onChangeQuoteRequest({
                    stops: [
                      quoteRequest.stops[0],
                      {
                        ...quoteRequest.stops[1],
                        start: targetDeliveryDate
                          ? DateTime.fromObject(targetDeliveryDate)
                              .setZone(quoteRequest.stops?.[1]?.address?.iana_timezone ?? "UTC", {
                                keepLocalTime: true,
                              })
                              .toISO()
                          : null,
                      },
                    ],
                  })
                }
              />
            </div>
            <div className="col-span-4">
              <Text size="medium" weight="bold">
                {t("common:additionalNotes")}
              </Text>
              <TextInput
                multiline
                value={quoteRequest.note}
                rows={4}
                placeholder="Enter details about the carrier and your routing preferences"
                onChange={(event) => onChangeQuoteRequest({ note: event.target.value })}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DrayageRoutingAndDatesView;
