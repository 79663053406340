import { ModeEnum } from "types/Mode";

import { QuoteRequest } from "../quote-requests";
import { ShipperTemplate } from "./types";

export function mapQuoteRequestsToShipperTemplates(quoteRequests: QuoteRequest[]): ShipperTemplate[] {
  return quoteRequests.map((quoteRequest) => {
    const mode = quoteRequest.mode;
    const hasIncoterms = mode === ModeEnum.FCL || mode === ModeEnum.AIR || mode === ModeEnum.DRAYAGE;
    const hasRouting = mode === ModeEnum.FCL || mode === ModeEnum.AIR;

    const shipperTemplate: ShipperTemplate = {
      id: quoteRequest.id,
      mode: quoteRequest.mode,
      equipment: quoteRequest.equipment,
      name: quoteRequest.template_name ?? "",
      incoterms: hasIncoterms
        ? {
            short: quoteRequest.incoterms ?? "",
            long: quoteRequest.incoterms ?? "",
          }
        : undefined,
      routing: hasRouting
        ? {
            short: quoteRequest.routing_type ?? "",
            long: quoteRequest.routing_type ?? "",
          }
        : undefined,
      stopTemplates: quoteRequest.stops.map((stop, index) => {
        const addressType = stop.address?.type;
        const isPort = addressType === "RAILHEAD" || addressType === "AIRPORT" || addressType === "SEAPORT";
        const port = { short: "", long: "" };

        if (mode === "FCL" || mode === "DRAYAGE") {
          const portName = stop.address?.port_name ? stop.address.port_name : `Port of ${stop.address?.city}`;
          const country = stop.address?.country_code === "US" ? stop.address.country_code : stop.address?.country_name;
          port.short = portName;
          port.long = [portName, country].filter(Boolean).join(", ");
        }

        if (mode === "AIR") {
          port.short = stop.address?.airport_iata_code ?? "";
          port.long = stop.address?.airport_name ?? "";
        }

        return {
          position: index,
          address: {
            address_1: stop.address?.address_1 ?? null,
            address_2: stop.address?.address_2 ?? null,
            city: stop.address?.city ?? null,
            country_code: stop.address?.country_code ?? null,
            country_name: stop.address?.country_name ?? null,
            google_place_description: stop.address?.google_place_description ?? null,
            name: stop.address?.name ?? null,
            province_code: stop.address?.province_code ?? null,
            province_name: stop.address?.province_name ?? null,
            type: stop.address?.type ?? "OTHER",
            zip: stop.address?.zip ?? null,
            port: isPort ? port : undefined,
          },
        };
      }),
    };

    return shipperTemplate;
  });
}
