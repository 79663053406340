import { ComponentProps } from "react";

import OverflowMenuView from "components/OverflowMenuView";
import withAsync from "components/withAsync";
import noop from "lodash/noop";
import CreateTemplateDialogView from "pages/shipper/components/CreateTemplateDialogView";
import useCreateTemplateDialog from "pages/shipper/hooks/useCreateTemplateDialog";
import { useTranslation } from "react-i18next";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";

const QuoteRequestHeaderActions = withAsync({
  useHook: useLoadQuoteRequest,
  Component: ({ loadedData: { quoteRequest } }) => {
    const { t } = useTranslation(["requestManagement"]);
    const createTemplateDialog = useCreateTemplateDialog({ quoteRequestId: quoteRequest.id, mode: quoteRequest.mode });

    const actions: ComponentProps<typeof OverflowMenuView>["items"] = [
      {
        label: t("requestManagement:quoteRequestHeader_actions_viewQuoteRequest_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_saveAsTemplate_label"),
        onClick: createTemplateDialog.dialogOpen.setTrue,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_cancelShipment_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_duplicateQuoteRequest_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_resendQuoteRequest_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_editDetails_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
      {
        label: t("requestManagement:quoteRequestHeader_actions_closeQuote_label"),
        onClick: noop,
        disabled: true /** @todo */,
      },
    ];

    return (
      <>
        <OverflowMenuView label={t("requestManagement:quoteRequestHeader_actions_label")} items={actions} />
        <CreateTemplateDialogView
          isOpen={createTemplateDialog.dialogOpen.value}
          onClose={createTemplateDialog.dialogOpen.setFalse}
          onSubmit={createTemplateDialog.onSubmit}
          loading={createTemplateDialog.loading}
        />
      </>
    );
  },
});

export default QuoteRequestHeaderActions;
