import { useCreateQuoteRequestMutation } from "api/rest/quote-requests/createQuoteRequest";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import QuoteRequestTemplates from "features/quote-request-templates/QuoteRequestTemplates";
import StepTitle from "pages/shipper/pages/request-quote/components/StepTitle";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import useChangeQuoteRequestRoute from "../../hooks/dispatches/useChangeQuoteRequestRoute";
import useQuoteRequestStep from "../../hooks/dispatches/useQuoteRequestStep";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const LaneStep = withAsync({
  useHook: () => {
    useQuoteRequestStep("initial");

    return {
      isLoading: false,
    };
  },
  Component: () => {
    const { t } = useTranslation(["quoteRequest", "shipper"]);
    const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
    const selectedMode = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.selectedMode);
    const [createQuoteRequest, createQuoteRequestMutation] = useCreateQuoteRequestMutation();

    const handleCreateQuoteRequest = async (quoteRequestId?: number) => {
      if (!selectedMode) {
        throw new Error("Expected mode to be selected");
      }

      const response = await createQuoteRequest({
        body: {
          mode: selectedMode,
          quoteRequestId,
        },
      }).unwrap();

      changeQuoteRequestRoute({
        step: QuoteRequestPageSteps.Routing,
        quoteRequestId: response.data.quoteRequest.id,
      });
    };

    if (!selectedMode) {
      return <Redirect to="/shipper/requests/create" />;
    }

    if (createQuoteRequestMutation.isLoading) {
      return <Loading spinnerOnly />;
    }

    return (
      <div className="flex flex-col h-full">
        <div className="p-6">
          <StepTitle
            boxProps={{ width: "100%" }}
            title={t("shipper:laneStep.title")}
            subtitle={t("shipper:laneStep.subtitle")}
          />
        </div>
        <QuoteRequestTemplates
          mode={selectedMode}
          onClickCreateQuoteRequest={handleCreateQuoteRequest}
          onClickQuoteRequestTemplate={({ quoteRequestId }) => handleCreateQuoteRequest(quoteRequestId)}
        />
      </div>
    );
  },
});

export default LaneStep;
