import { FC, VFC } from "react";

import { NewReleases } from "@material-ui/icons";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import classNames from "classnames";
import AppBar from "components/AppBar";
import LoggedIn from "components/auth/LoggedIn";
import LoggedOut from "components/auth/LoggedOut";
import ImpersonationBanner from "components/ImpersonationBanner";
import PortexLogoView from "components/PortexLogoView";
import VersionBanner from "components/VersionBanner";
import useLDFlag from "hooks/useLDFlag";
import { useUserContext } from "hooks/useUserContext";
import first from "lodash/first";
import { useTranslation } from "react-i18next";
import { Link, LinkProps, useLocation } from "react-router-dom";

import BrokerUserAvatar from "./BrokerUserAvatar";

const AccountActionLink: FC<LinkProps & { showAlert?: boolean }> = ({ showAlert, ...props }) => {
  return (
    <Link
      {...props}
      className={classNames(
        "rounded-lg px-4 py-2",
        "bg-white hover:bg-blue-100 cursor-pointer",
        "text-small text-brandBlue font-bold uppercase tracking-[2px]",
        props.className
      )}
    >
      {!!showAlert && (
        <div className="relative">
          <NewReleases className="absolute left-[100%] -top-3  text-red-500" />
        </div>
      )}
      {props.children}
    </Link>
  );
};

const LoggedInAndViewingAs: VFC = () => {
  const { t } = useTranslation(["broker", "common"]);
  const { auth0User } = useUserContext();
  const getCurrentUserQuery = useGetCurrentUserQuery();

  const user = getCurrentUserQuery.data?.data.user;

  return (
    <div className="flex flex-col text-x-small opacity-70 items-end">
      <LoggedIn>
        <div>{t("common:loggedInAs", { as: auth0User?.email ?? user?.email })}</div>
      </LoggedIn>

      <LoggedIn as={["uut"]}>
        <div>{t("common:viewingAs", { as: user?.email })}</div>
      </LoggedIn>

      <LoggedOut withUut={true}>
        <div>{t("common:viewingAs", { as: user?.email })}</div>
      </LoggedOut>
    </div>
  );
};

const BrokerLayout: FC = ({ children }) => {
  const { t } = useTranslation(["broker"]);
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const location = useLocation();
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");

  const navigationItems = [
    {
      pathnames: ["/broker/quotes", "/broker/requests", "/broker/quote", "/broker/dispatches"],
      label: t("broker:layout_navigationItem_quotes"),
      hide: !enableBrokerQuotesPage,
    },
    {
      pathnames: ["/broker/bids"],
      label: t("broker:layout_navigationItem_bids"),
    },
    {
      pathnames: ["/broker/shipments", "/broker/loads"],
      label: t("broker:layout_navigationItem_shipments"),
    },
    {
      pathnames: ["/broker/shippers"],
      label: t("broker:layout_navigationItem_shippers"),
    },
    {
      pathnames: ["/broker/insights"],
      label: t("broker:layout_navigationItem_insights"),
    },
  ];

  const isActiveItem = (pathnames: string[]): boolean => {
    return pathnames.some((pathname) => location.pathname.includes(pathname));
  };

  if (!enableBrokerAppBar) {
    return <>{children}</>;
  }

  return (
    <>
      <ImpersonationBanner fullWidth />
      <VersionBanner fullWidth />
      <AppBar className="min-h-[50px] shadow z-50">
        <div className="py-1 pr-2 pl-3 w-full h-full bg-grey-900 text-white flex items-center">
          <div className="flex items-center space-x-6">
            <PortexLogoView color="white" />

            <div className="flex items-center space-x-4">
              {navigationItems.map((navigationItem) => {
                if (navigationItem.hide) {
                  return null;
                }

                return (
                  <Link
                    key={first(navigationItem.pathnames)}
                    to={{ pathname: first(navigationItem.pathnames) }}
                    className={classNames(
                      "rounded px-4 py-1.5 text-white font-bold text-medium hover:bg-white/20 cursor-pointer",
                      isActiveItem(navigationItem.pathnames) && "bg-white/10"
                    )}
                  >
                    {navigationItem.label}
                  </Link>
                );
              })}
            </div>
          </div>

          <div className="flex flex-1 justify-end font-bold items-center space-x-6">
            <LoggedInAndViewingAs />

            <LoggedIn as={["broker", "unclaimed"]}>
              <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
                {t("broker:claimAccount")}
              </AccountActionLink>
            </LoggedIn>

            <LoggedOut withUut={false}>
              <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
                {t("broker:signUp")}
              </AccountActionLink>
            </LoggedOut>

            <LoggedOut withUut={true}>
              <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
                {t("broker:claimAccount")}
              </AccountActionLink>
            </LoggedOut>

            <BrokerUserAvatar />
          </div>
        </div>
      </AppBar>

      {children}
    </>
  );
};

export default BrokerLayout;
