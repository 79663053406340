import { EM_DASH } from "constants/index";

import { portexColor } from "@portex-pro/ui-components";
import Text from "components/Text";
import withAsync from "components/withAsync";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";
import getTransitTimeString from "utils/getTransitTimeString";

import useLoadQuoteRequestAndQuote from "../../hooks/quotes/useLoadQuoteRequestAndQuote";

const QuoteSummaryContainer = withAsync({
  useHook: useLoadQuoteRequestAndQuote,
  Component: ({ loadedData: { quote } }) => {
    const { t } = useTranslation(["common", "shipments"]);

    if (quote.mode !== "DRAYAGE") {
      return null;
    }

    return (
      <div className="w-full">
        <div className="border-2 border-blue-500 rounded flex flex-row py-6 px-8 justify-between bg-white">
          <div className="flex flex-col">
            <Text
              size="x-large"
              weight="bold"
              typographyProps={{ style: { color: portexColor.blue500, fontSize: "48px" } }}
            >
              {formatUSD(quote.total_amount)}
            </Text>
          </div>

          <div className="flex flex-row space-x-5">
            <div className="flex flex-col">
              <Text size="small">{t("common:validUntil")}</Text>
              <Text size="large" weight="bold">
                {quote.valid_until ? DateTime.fromISO(quote.valid_until).toLocaleString(DateTime.DATE_MED) : EM_DASH}
              </Text>
            </div>

            <div className="flex flex-col">
              <Text size="small">{t("shipments:shipmentQuotes_estimatedTransitTime_label")}</Text>
              <Text size="large" weight="bold">
                {getTransitTimeString(quote.min_transit_time, quote.max_transit_time)}
              </Text>
            </div>

            <div className="flex flex-col">
              <Text size="small">{t("shipments:partner_columnLabel")}</Text>
              <Text size="large" weight="bold">
                {quote.company_name ? quote.company_name : quote.submitter_email}
              </Text>
              {quote.company_name && (
                <Text size="small" typographyProps={{ color: "textSecondary" }}>
                  {quote.submitter_email}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default QuoteSummaryContainer;
