import { EM_DASH } from "constants/index";

import { useMemo } from "react";

import { Button, portexColor } from "@portex-pro/ui-components";
import { QuoteUnionByMode } from "api/rest/quote-requests";
import classNames from "classnames";
import Text from "components/Text";
import withAsync from "components/withAsync";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";
import ButtonBookNow from "pages/shipper/components/ButtonBookNow";
import PendingQuote from "pages/shipper/pages/quote-request-details/components/PendingQuote";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { formatUSD } from "utils/formatCurrency";
import getTransitTimeString from "utils/getTransitTimeString";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";

const QuoteListContainer = withAsync({
  useHook: () => useLoadQuoteRequest(),
  Component: ({ loadedData: { quoteRequest } }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const sortBy = useRequestManagementSliceSelector((state) => state.quoteIndexSlice.sortBy);
    const { t } = useTranslation(["common", "shipper", "shipments"]);

    const cheapestQuoteAmount = useMemo(
      () => quoteRequest.quotes.reduce((amount, quote) => Math.min(quote.total_amount, amount), Infinity),
      [quoteRequest]
    );

    const quotes = useMemo(() => {
      let sortedQuotes = quoteRequest.quotes.filter((quote) => quote.mode === "DRAYAGE");

      if (sortBy === "CHEAPEST") {
        sortedQuotes = orderBy(sortedQuotes, "total_amount", "asc");
      } else if (sortBy === "FASTEST") {
        sortedQuotes = orderBy(sortedQuotes, "min_transit_time", "asc");
      } else {
        sortedQuotes = orderBy(
          sortedQuotes,
          (quote) => {
            // This typing is necessary to fool the compiler into believing this is a drayage quote
            const quoteValidUntil = (quote as QuoteUnionByMode & { mode: "DRAYAGE" }).valid_until;

            if (!quoteValidUntil) {
              return Infinity;
            }

            return DateTime.fromISO(quoteValidUntil).toMillis();
          },
          "desc"
        );
      }

      return sortedQuotes;
    }, [quoteRequest, sortBy]);

    if (quoteRequest.mode !== "DRAYAGE") {
      return null;
    }

    return (
      <>
        <div className="w-full h-full space-y-2">
          {quotes.length === 0 &&
            quoteRequest.partners.map(({ company_name }) => <PendingQuote companyName={company_name} />)}
          {quotes.map((quote) => {
            if (quote.mode !== "DRAYAGE") {
              return null;
            }

            return (
              <div
                className={classNames("border rounded p-5 flex flex-row justify-between bg-white", {
                  "border-2 border-green-500": quote.total_amount === cheapestQuoteAmount,
                })}
              >
                <div className="flex flex-col">
                  <Text size="large" weight="bold">
                    {quote.company_name}
                  </Text>
                </div>

                <div className="flex flex-col">
                  <Text size="small">{t("common:validUntil")}</Text>
                  <Text size="medium" weight="bold">
                    {quote.valid_until
                      ? DateTime.fromISO(quote.valid_until).toLocaleString(DateTime.DATE_MED)
                      : EM_DASH}
                  </Text>
                </div>

                <div className="flex flex-col">
                  <Text size="small">{t("shipments:shipmentQuotes_estimatedTransitTime_label")}</Text>
                  <Text size="medium" weight="bold">
                    {getTransitTimeString(quote.min_transit_time, quote.max_transit_time)}
                  </Text>
                </div>

                <div className="flex flex-col">
                  <Text size="small">{t("common:totalAmount")}</Text>
                  <Text
                    size="medium"
                    weight="bold"
                    typographyProps={{
                      style: { color: quote.total_amount === cheapestQuoteAmount ? portexColor.green500 : undefined },
                    }}
                  >
                    {formatUSD(quote.total_amount)}
                  </Text>
                </div>

                <div className="flex flex-row space-x-2 min-w-[310px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ whiteSpace: "nowrap", width: "100%", padding: "0.5rem 2rem" }}
                    onClick={() => history.push(`${match.url}/quote/${quote.drayage_quote_id}`)}
                  >
                    {t("shipments:shipmentQuotes_viewQuote_button")}
                  </Button>
                  {quoteRequest.status === "BOOKED" || quotes.some((quote) => quote.status === "BOOKED") ? (
                    <>
                      {quote.status === "BOOKED" ? (
                        <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none" }} />
                      ) : (
                        <ButtonBookNow bookNowVariant="booked-by-other" />
                      )}
                    </>
                  ) : (
                    <ButtonBookNow
                      bookNowVariant="book"
                      // @ts-expect-error: This is OK
                      component={Link}
                      to={`${match.url}/quote/${quote.drayage_quote_id}`}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  },
});

export default QuoteListContainer;
