import { useCallback, useContext } from "react";

import { useCreateQuoteRequestTemplateMutation } from "api/rest/quote-request-templates/createQuoteRequestTemplate";
import { useUpdateShipperQuoteRequestMutation } from "api/rest/quote-requests/updateShipperQuoteRequest";
import { getShipmentSourcesApi } from "api/rest/shipments";
import { CargoGroupContext } from "features/cargo-groups/CargoGroupProvider";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Sentry } from "sentry";

import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import useChangeQuoteRequestRoute, { QuoteRequestRouteOptions } from "../dispatches/useChangeQuoteRequestRoute";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSaveAndChangeRoute = () => {
  const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
  const { saveAsTemplate, templateName } = useRequestManagementSliceSelector((state) => state.quoteRequestSlice);
  const quoteRequest = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.quoteRequest);
  const [updateQuoteRequest] = useUpdateShipperQuoteRequestMutation();
  const [createQuoteRequestTemplate] = useCreateQuoteRequestTemplateMutation();
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { save: saveCargoGroups } = useContext(CargoGroupContext);
  const dispatch = useDispatch();

  return useCallback(
    async (options: QuoteRequestRouteOptions, sendQuoteRequest?: boolean) => {
      if (!quoteRequest) {
        return;
      }

      try {
        if (!!sendQuoteRequest && !!saveAsTemplate) {
          await createQuoteRequestTemplate({
            urlParams: { quoteRequestId: quoteRequest.id },
            body: {
              templateName,
            },
          }).unwrap();
        }

        await updateQuoteRequest({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: { ...quoteRequest, status: sendQuoteRequest ? "SENT" : quoteRequest?.status },
        }).unwrap();
        await saveCargoGroups();
        changeQuoteRequestRoute(options);
        dispatch(getShipmentSourcesApi.util.invalidateTags(["SOURCES"]));
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
      }
    },
    [
      quoteRequest,
      saveAsTemplate,
      updateQuoteRequest,
      saveCargoGroups,
      changeQuoteRequestRoute,
      dispatch,
      createQuoteRequestTemplate,
      templateName,
      enqueueSnackbar,
      t,
    ]
  );
};
