import { VFC } from "react";

import { Button, createStyles, makeStyles, Paper, portexColor } from "@portex-pro/ui-components";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import { formatUSD } from "../../../../utils/formatCurrency";
import { ShipmentMode, ShipmentQuoteDetails } from "../types/domain";

const useStyles = makeStyles(() =>
  createStyles({
    quoteWrapper: {
      padding: "12px",
      gap: "6px",
      borderWidth: "2px",
      borderColor: portexColor.grey300,
      borderStyle: "solid",
      minHeight: "124px",
      alignItems: "center",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 150px",
    },
    quoteWrapper_booked: {
      borderColor: portexColor.green500,
      backgroundColor: portexColor.green100,
    },
    cell: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "& > :first-child": {
        fontSize: "16px",
      },
    },
    lastCell: {
      textAlign: "center",
    },
    largeValue: {
      fontSize: "20px",
      fontWeight: 700,
    },
    notes: {
      fontSize: "14px",
    },
    brokerName: {
      fontWeight: "bold",
      fontSize: "20px",
    },
    bookedTag: {
      fontSize: "12px",
      fontWeight: 700,
      letterSpacing: "0.05em",
      color: portexColor.green500,
      textTransform: "uppercase",
    },
    quoteCounter: {
      fontSize: "12px",
    },
  })
);

interface ShipmentQuotesTabItemViewProps {
  quote: ShipmentQuoteDetails;
  isBooked?: boolean;
  quoteRequestId: number;
  mode: ShipmentMode;
}

const ShipmentQuotesTabItemView: VFC<ShipmentQuotesTabItemViewProps> = ({
  quote,
  quoteRequestId,
  isBooked = false,
  mode,
}) => {
  const styles = useStyles();
  const { t } = useTranslation("shipments");

  const transitTime = uniq([quote.transitTime.min, quote.transitTime.max].filter(Boolean));

  return (
    <Paper className={[styles.quoteWrapper, isBooked && styles.quoteWrapper_booked].filter(Boolean).join(" ")}>
      <div className={styles.cell}>
        <div className={styles.brokerName}>{quote.companyName}</div>
        {isBooked && <div className={styles.bookedTag}>{t("shipmentQuotes_booked_badge")}</div>}
        {quote.quotesFromTheSameCompany > 1 && (
          <div className={styles.quoteCounter}>
            {t("shipmentQuotes_XOutOfCount", {
              x: quote.quoteNumberWithinGroup,
              count: quote.quotesFromTheSameCompany,
            })}
          </div>
        )}
      </div>
      <div className={styles.cell}>
        <div>{t("shipmentQuotes_estimatedTransitTime_label")}</div>
        <div className={styles.largeValue}>
          {transitTime.length
            ? transitTime.join("-") + " " + t("shipmentQuotes_transitTime_days", { count: transitTime.at(-1) })
            : EMPTY_CELL_HYPHEN}
        </div>
      </div>
      <div className={styles.cell}>
        <div>{t("shipmentQuotes_rate_label")}</div>
        <div className={styles.largeValue}>{formatUSD(quote.ratePerLoad)}</div>
      </div>
      <div className={styles.cell}>
        <div>{t("shipmentQuotes_notes_label")}</div>
        <div className={styles.notes}>{quote.notes || EMPTY_CELL_HYPHEN}</div>
      </div>
      <div className={styles.lastCell}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={(() => {
            if (mode === "DRAYAGE") {
              return `/shipper/requests/quotes/${quoteRequestId}/quote/${quote.id}`;
            }
            return `/shipper/quotes/${quoteRequestId}/${quote.id}`;
          })()}
        >
          {t("shipmentQuotes_viewQuote_button")}
        </Button>
      </div>
    </Paper>
  );
};

export default ShipmentQuotesTabItemView;
