import { ReactElement } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@portex-pro/ui-components";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import { Redirect } from "react-router-dom";
import { useQueryParams, StringParam } from "use-query-params";

import LoginError from "../../components/errors/LoginError";
import Loading from "../../components/Loading";
import { REACT_APP_HOST } from "../../env";

const LogoutPage = (): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, user, logout } = useAuth0();
  const [{ returnTo, error, error_description: errorDescription, email }] = useQueryParams({
    returnTo: StringParam,
    error: StringParam,
    error_description: StringParam,
    email: StringParam,
  });

  const urlSearchString = new URLSearchParams(
    omitBy(
      {
        returnTo: returnTo ?? "",
        email: email ?? user?.email ?? "",
      },
      isEmpty
    )
  ).toString();

  if (error || errorDescription) {
    return (
      <LoginError error={error} errorDescription={errorDescription}>
        <Typography variant="h4" align="center">
          Something went wrong <br /> while you were logging out...
        </Typography>
      </LoginError>
    );
  }

  if (isAuthLoading) {
    return <Loading message="Loading..." showPortexLogo />;
  }

  if (!isAuthenticated && !isAuthLoading) {
    return <Redirect to={`/login?${urlSearchString}`} />;
  }

  /**
   * Auth0's logout will always use the logout URL specified in "Logout URLs" in the application Settings.
   * Unelss you specify a "returnTo" logout option, then it will use the first Allowed Logout URL it matches.
   * This isn't well documented -- so know that 'logout' operates by default on a config in the auth0 dashboard.
   */
  logout({ returnTo: `${REACT_APP_HOST}/logout?${urlSearchString}` });
  return <Loading message="Logging out..." showPortexLogo />;
};

export default LogoutPage;
