import { VFC } from "react";

import BrokerRequestsFilterBarContainer from "./containers/BrokerRequestsFilterBarContainer";
import BrokerRequestsIndexTableContainer from "./containers/BrokerRequestsIndexTableContainer";
import { useBrokerRequestsIndexSlices } from "./store/brokerRequestsIndexStore";

const BrokerRequestsIndexPage: VFC = () => {
  useBrokerRequestsIndexSlices();
  return (
    <>
      <BrokerRequestsFilterBarContainer />
      <BrokerRequestsIndexTableContainer />
    </>
  );
};

export default BrokerRequestsIndexPage;
