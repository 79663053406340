import { ReactElement } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/Loading";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

const ClaimUserAccount = (): ReactElement => {
  const [email] = useQueryParam<string>("email");
  const [signupToken] = useQueryParam("signupToken", StringParam);
  const [initialScreen] = useQueryParam("initialScreen", withDefault(StringParam, "signUp"));
  const { loginWithRedirect } = useAuth0();

  if (!signupToken) {
    throw new Error("No signup token provided to claim user account");
  }

  const searchParams = new URLSearchParams({ signupToken });
  const returnTo = "claim-complete" + "?" + searchParams.toString();

  loginWithRedirect({
    login_hint: email,
    firstName: "",
    lastName: "",
    allowSignUp: initialScreen === "signUp",
    allowLogin: initialScreen === "login",
    initialScreen,
    appState: { returnTo },
    signupToken,
  });

  return <Loading showPortexLogo />;
};

export default ClaimUserAccount;
