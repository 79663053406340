import { baseRestApi } from "api/rest/baseRestApi";

type UrlParams = {
  templateId: number;
};

type QueryArgs = {
  urlParams: UrlParams;
};

module.hot?.accept();

export const deleteQuoteRequestTemplateApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      __DEPRECATED__deleteQuoteRequestTemplate: builder.mutation<void, QueryArgs>({
        query: ({ urlParams: { templateId } }) => ({
          method: "DELETE",
          url: `shipper/quote-requests/templates/${templateId}`,
        }),
        invalidatesTags: ["TEMPLATES"],
      }),
      deleteQuoteRequestTemplate: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams }) => ({
          method: "DELETE",
          url: `shipper/quote-requests/${urlParams.templateId}`,
        }),
        invalidatesTags: ["TEMPLATES"],
      }),
    }),
  });

export const { use__DEPRECATED__deleteQuoteRequestTemplateMutation, useDeleteQuoteRequestTemplateMutation } =
  deleteQuoteRequestTemplateApi;
