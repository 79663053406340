import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

export type BrokerRequestsIndexFilterState = {
  transportationMode: TransportationMode;
  searchQuery: string;
};

const initialState: BrokerRequestsIndexFilterState = {
  transportationMode: TransportationMode.All,
  searchQuery: "",
};

const slice = createSlice({
  name: "brokerRequestsIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<BrokerRequestsIndexFilterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const brokerRequestsIndexFilterActions = usableActions(slice.actions);
export default slice;
