import { useEffect } from "react";

import { Container, Paper } from "@portex-pro/ui-components";
import { BrokerQuoteResponse } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import { useSubmitBrokerQuoteMutation } from "api/rest/quote-requests/submitBrokerQuote";
import NotFound404 from "components/errors/NotFound404";
import HtmlTitle from "components/HtmlTitle";
import Loading from "components/Loading";
import PortexAppBar from "components/PortexAppBar";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useSubmitterStore } from "pages/shipper/pages/quotes/hooks/useSubmitterStore";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import { validateEmail } from "utils/validateEmail";

import SubmitDeadlineAlertView from "../dispatch-submit/views/SubmitDeadlineAlertView";
import { useSetLineItems, useSetQuote } from "./store/brokerQuoteSubmissionSlice";
import {
  useBrokerQuoteSubmissionSlices,
  useBrokerQuoteSubmissionSliceSelector,
} from "./store/brokerQuoteSubmissionStore";
import DrayageSummaryView from "./views/DrayageSummaryView";
import QuoteDetailsView from "./views/QuoteDetailsView";

const BrokerQuoteSubmissionPage = withAsync({
  useHook: () => {
    const params = useParams<{ quoteRequestGuid: string }>();
    useBrokerQuoteSubmissionSlices();
    const setQuote = useSetQuote();

    const { submitterEmail } = useSubmitterStore();

    useEffect(() => {
      if (submitterEmail) {
        setQuote({ submitterEmail });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useGetBrokerQuoteRequestQuery({
      urlParams: {
        quoteRequestGuid: params.quoteRequestGuid,
      },
    });
  },
  useHandleError: () => {
    const { t } = useTranslation("broker");
    const { enqueueSnackbar } = useSnackbar();

    return (error) => {
      if (error?.status === 403) {
        enqueueSnackbar(t("quoteSubmissionPage_message"), { variant: "warning", preventDuplicate: true });
        return;
      }

      return Sentry.captureException(error);
    };
  },
  LoadingComponent: <Loading showPortexLogo />,
  Component: ({ loadedData }) => {
    const quoteRequest = loadedData?.data?.data?.quoteRequest;
    const { quote, lineItems } = useBrokerQuoteSubmissionSliceSelector((state) => state.brokerQuoteSubmissionSlice);
    const [submitQuote, { isLoading }] = useSubmitBrokerQuoteMutation();
    const onChange = useSetQuote();
    const onChangeLineItem = useSetLineItems();
    const { t } = useTranslation(["broker", "common"]);
    const { enqueueSnackbar } = useSnackbar();
    const { setSubmitterEmail } = useSubmitterStore();
    const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
    const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");

    const isValid =
      !!quote.validUntil &&
      !!quote.minTransitTime &&
      !!quote.maxTransitTime &&
      !!quote.submitterEmail &&
      lineItems.every((lineItem) => lineItem.amount !== undefined) &&
      validateEmail(quote.submitterEmail);

    const onSubmitQuote = async () => {
      if (!quoteRequest || !quoteRequest.guid || !quote.submitterEmail || !isValid) {
        return;
      }

      const total = lineItems.reduce((total, lineItem) => total + (lineItem.amount ?? 0), 0);

      try {
        await submitQuote({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: {
            ...quote,
            totalAmount: total,
            lineItems: lineItems.map((lineItem) => ({
              amount: lineItem.amount ?? 0,
              cargo_group_id: lineItem.type === "FREIGHT_CHARGE" ? lineItem.cargoGroup.id : undefined,
              type: lineItem.type,
            })),
          } as BrokerQuoteResponse,
        }).unwrap();
        setSubmitterEmail(quote.submitterEmail);
        enqueueSnackbar(t("broker:quoteSubmission.successText"), { variant: "success" });
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    if (loadedData.isError || !quoteRequest) {
      return <NotFound404 showAppBar useMarketingUrl />;
    }

    return (
      <>
        <HtmlTitle title={`${t("broker:submitQuoteForm.title")} ${quoteRequest.shipper_name}`} />
        <PortexAppBar
          shipperName={quoteRequest.shipper_name}
          useMarketingUrl
          hidePortexLogo={!!enableBrokerAppBar}
          backButtonTo={!!enableBrokerAppBar && !!enableBrokerQuotesPage && `/broker/quotes`}
        />

        <div className="w-full overflow-auto">
          <Container maxWidth="lg" className="py-5 space-y-5">
            {quoteRequest.deadline_respond_at && (
              <SubmitDeadlineAlertView deadlineRespondAt={quoteRequest.deadline_respond_at} />
            )}
            <Paper className="Por-outlined-light" elevation={8}>
              <DrayageSummaryView quoteRequest={quoteRequest} />
              <QuoteDetailsView
                quote={quote}
                lineItems={lineItems}
                onChangeLineItem={onChangeLineItem}
                onChange={(changes) => onChange(changes)}
                onSubmit={onSubmitQuote}
                isLoading={isLoading}
                isValid={isValid}
              />
            </Paper>
          </Container>
        </div>
      </>
    );
  },
});

export default BrokerQuoteSubmissionPage;
