import { VFC } from "react";

import noop from "lodash/noop";
import { DateTime } from "luxon";

import { brokerInsightsIndexFilterActions } from "../store/brokerInsightsIndexFilterSlice";
import { useBrokerInsightsIndexSliceSelector } from "../store/brokerInsightsIndexStore";
import BrokerInsightsFilterBarView from "../views/BrokerInsightsFilterBarView";

const BrokerLoadsFilterBarContainer: VFC = () => {
  const { searchQuery, transportationMode } = useBrokerInsightsIndexSliceSelector(
    (store) => store.brokerInsightsIndexFilterSlice
  );
  const setFilterState = brokerInsightsIndexFilterActions.useSetFilterState();
  const now = Date.now();
  const lastRefreshedAt = DateTime.fromMillis(now).toLocaleString(DateTime.TIME_WITH_SECONDS);

  return (
    <BrokerInsightsFilterBarView
      isLoading={false}
      onChangeFilter={setFilterState}
      onRefresh={noop}
      searchQuery={searchQuery}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default BrokerLoadsFilterBarContainer;
