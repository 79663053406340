import { ReactElement } from "react";

import { Box, Grid, ModeTransport, Typography } from "@portex-pro/ui-components";
import { quoteRequestActions } from "app/pages/request-management/store/quoteRequestSlice";
import { ReactComponent as DrayageContainerIcon } from "assets/drayage/drayage-container-filled.svg";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ModeEnum, ModeQuotes } from "types/Mode";

import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";

const SelectModeStep = (): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  const history = useHistory();
  const enableDrayage = useLDFlag("enableDrayage");
  const shipmentsTab = useLDFlag("shipmentsTab");
  const selectMode = quoteRequestActions.useSelectMode();
  const resetState = quoteRequestActions.useResetState();
  const isDrayageDisabled = !enableDrayage || !shipmentsTab;

  const handleClick = (mode: ModeQuotes) => () => {
    if (mode === "DRAYAGE") {
      resetState();
    }

    selectMode(mode);

    if (mode === "DRAYAGE") {
      history.push(`/shipper/requests/create/quotes`);
      return;
    }

    // the old quote request flow goes here
    history.push(`/shipper/request-quote/${mode}`);
  };

  return (
    <LayoutColumnTwo.Content hideFooter>
      <Box display="flex">
        <Box flexGrow={1}>
          <Box py={10} mx="auto" textAlign="center">
            <Typography variant="h5" component="h1">
              <strong>{t("shipper:modeOfTransporation")}</strong>
            </Typography>
            <Box mt={1} mb={6}>
              <Typography>{t("shipper:selectModeStep.selectMode")}</Typography>
            </Box>
            <Grid container spacing={4} justify="center">
              <Grid item xs="auto">
                <ModeTransport
                  label={t(`shipper:selectModeStep.${ModeEnum.FTL}`)}
                  mode="trucking"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(ModeEnum.FTL)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={t(`shipper:selectModeStep.${ModeEnum.LTL}`)}
                  mode="truckingLTL"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(ModeEnum.LTL)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={""}
                  style={{ width: 192, height: "100%" }}
                  mode="trucking"
                  iconProps={{ style: { display: "none" } }}
                  disabled={isDrayageDisabled}
                  enableComingSoon={isDrayageDisabled}
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(ModeEnum.DRAYAGE)}
                >
                  <div className="p-8 relative top-[-10px]">
                    <DrayageContainerIcon />
                  </div>
                  <Typography variant="body2" style={{ fontWeight: "bold" }} align="center" component="div">
                    {t(`shipper:selectModeStep.${ModeEnum.DRAYAGE}`)}
                  </Typography>
                </ModeTransport>
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={t(`shipper:selectModeStep.${ModeEnum.FCL}`)}
                  mode="ocean"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(ModeEnum.FCL)}
                />
              </Grid>
              <Grid item xs="auto">
                <ModeTransport
                  label={t(`shipper:selectModeStep.${ModeEnum.AIR}`)}
                  mode="air"
                  fullWidth
                  iconSize={140}
                  onClick={handleClick(ModeEnum.AIR)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default SelectModeStep;
