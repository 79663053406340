import { VFC } from "react";

import BrokerBidsFilterBarContainer from "./containers/BrokerBidsFilterBarContainer";
import BrokerBidsIndexTableContainer from "./containers/BrokerBidsIndexTableContainer";
import { useBrokerBidsIndexSlices } from "./store/brokerBidsIndexStore";

const BrokerBidsIndexPage: VFC = () => {
  useBrokerBidsIndexSlices();
  return (
    <>
      <BrokerBidsFilterBarContainer />
      <BrokerBidsIndexTableContainer />
    </>
  );
};

export default BrokerBidsIndexPage;
