import { VFC } from "react";

import ComingSoon from "components/ComingSoon";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import TableView, { TableViewColumns } from "components/TableView";

interface BrokerRequestsIndexTableViewProps {
  items: [];
  paginationOptions: {
    next: () => void;
    prev: () => void;
    setTake: (take: number) => void;
    take: number;
    page: number;
    total: number;
  };
  isLoading?: boolean;
}

const BrokerRequestsIndexTableView: VFC<BrokerRequestsIndexTableViewProps> = ({
  items,
  paginationOptions,
  isLoading,
}) => {
  const columns: TableViewColumns<{}> = [];

  return (
    <>
      <ComingSoon hideMessage />
      <TableView
        tableProps={{ style: { borderCollapse: "separate" } }}
        columns={columns}
        items={items}
        isLoading={isLoading}
      />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default BrokerRequestsIndexTableView;
