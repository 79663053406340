import { VFC } from "react";

import noop from "lodash/noop";
import { DateTime } from "luxon";

import { brokerShippersIndexFilterActions } from "../store/brokerShippersIndexFilterSlice";
import { useBrokerShippersIndexSliceSelector } from "../store/brokerShippersIndexStore";
import BrokerShippersFilterBarView from "../views/BrokerShippersFilterBarView";

const BrokerLoadsFilterBarContainer: VFC = () => {
  const { searchQuery } = useBrokerShippersIndexSliceSelector((store) => store.brokerShippersIndexFilterSlice);
  const setFilterState = brokerShippersIndexFilterActions.useSetFilterState();
  const now = Date.now();
  const lastRefreshedAt = DateTime.fromMillis(now).toLocaleString(DateTime.TIME_WITH_SECONDS);

  return (
    <BrokerShippersFilterBarView
      isLoading={false}
      onChangeFilter={setFilterState}
      onRefresh={noop}
      searchQuery={searchQuery}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default BrokerLoadsFilterBarContainer;
