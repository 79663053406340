import { EM_DASH } from "constants/index";

import { FC } from "react";

import { Divider } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import PortexAppBar from "components/PortexAppBar";
import useLDFlag from "hooks/useLDFlag";

const BrokerSubmitAppBarContainer: FC = () => {
  const { data: contract } = useGetBrokerBidContractQuery();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const shipperName = contract?.shipper.name ?? EM_DASH;

  return (
    <>
      <PortexAppBar
        shipperName={shipperName}
        useMarketingUrl
        hidePortexLogo={!!enableBrokerAppBar}
        backButtonTo={!!enableBrokerAppBar && `/broker/bids`}
      />
      <Divider />
    </>
  );
};

export default BrokerSubmitAppBarContainer;
