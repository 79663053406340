import { EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import uniqueId from "lodash/uniqueId";
import { RootState, StatesFromApisMap } from "types/Store";
import WithRequiredField from "types/WithRequiredField";
import usableActions from "utils/store/usableActions";

import createCargoGroupsApi from "../api/createCargoGroupsApi";
import getCargoGroupsApi from "../api/getCargoGroupsApi";
import { NormalizedCargoGroup } from "../api/types";
import { normalizeCargoGroups } from "../api/utils/normalizeCargoGroups";
import { isNormalizedCargoGroupValid } from "../utils/isNormalizedCargoGroupValid";

type CacheField<T> = Record<string, T>;

export const normalizedCargoGroupAdapter = createEntityAdapter<NormalizedCargoGroup>({
  selectId: (normalizedCargoGroup) => normalizedCargoGroup.id,
});

export const normalizedCargoGroupSelectors = normalizedCargoGroupAdapter.getSelectors();

export type State = {
  normalizedCargoGroups: EntityState<NormalizedCargoGroup>;
  // We will use deletedCargoGroups to track which cargo groups have been removed
  deletedCargoGroups: CacheField<number[] | undefined>;
  newCargoGroups: CacheField<string[] | undefined>;
  topLevelNewCargoGroups: CacheField<string[] | undefined>;
  updatedCargoGroups: CacheField<number[] | undefined>;
  validityMap: CacheField<Record<number | string, boolean>>;
};

export const initialState: State = {
  normalizedCargoGroups: normalizedCargoGroupAdapter.getInitialState(),
  deletedCargoGroups: {},
  newCargoGroups: {},
  topLevelNewCargoGroups: {},
  updatedCargoGroups: {},
  validityMap: {},
};

const cargoGroupsSlice = createSlice({
  name: "cargoGroupsSlice",
  initialState,
  reducers: {
    _validateCargoGroup: (state, action: PayloadAction<{ cacheId: string; cargoGroupId: number | string }>) => {
      if (state.validityMap[action.payload.cacheId] === undefined) {
        state.validityMap[action.payload.cacheId] = {};
      }

      const cargoGroup = normalizedCargoGroupSelectors.selectById(
        state.normalizedCargoGroups,
        action.payload.cargoGroupId
      );

      if (!cargoGroup) {
        delete state.validityMap[action.payload.cacheId][action.payload.cargoGroupId];
      } else {
        state.validityMap[action.payload.cacheId][action.payload.cargoGroupId] =
          isNormalizedCargoGroupValid(cargoGroup);
      }
    },
    _validateNestedCargoGroups: (state, action: PayloadAction<{ cacheId: string; cargoGroupId: number | string }>) => {
      cargoGroupsSlice.caseReducers._validateCargoGroup(
        state,
        cargoGroupsSlice.actions._validateCargoGroup({
          cacheId: action.payload.cacheId,
          cargoGroupId: action.payload.cargoGroupId,
        })
      );

      const cargoGroup = normalizedCargoGroupSelectors.selectById(
        state.normalizedCargoGroups,
        action.payload.cargoGroupId
      );

      const childIds = [...(cargoGroup?.handling_units ?? []), ...(cargoGroup?.packing_units ?? [])];

      childIds.forEach((childId) => {
        cargoGroupsSlice.caseReducers._validateNestedCargoGroups(
          state,
          cargoGroupsSlice.actions._validateNestedCargoGroups({
            cacheId: action.payload.cacheId,
            cargoGroupId: childId,
          })
        );
      });
    },
    _registerUpdatedCargoGroup: (state, action: PayloadAction<{ cacheId: string; id: number | string }>) => {
      if (typeof action.payload.id === "number") {
        const newUpdatedCargoGroups = state.updatedCargoGroups[action.payload.cacheId] ?? [];
        newUpdatedCargoGroups.push(action.payload.id);
        state.updatedCargoGroups[action.payload.cacheId] = uniq(newUpdatedCargoGroups);

        cargoGroupsSlice.caseReducers._validateCargoGroup(
          state,
          cargoGroupsSlice.actions._validateCargoGroup({
            cacheId: action.payload.cacheId,
            cargoGroupId: action.payload.id,
          })
        );
      }
    },
    _registerDeletedCargoGroup: (state, action: PayloadAction<{ cacheId: string; id: number | string }>) => {
      if (typeof action.payload.id === "number") {
        const newDeletedCargoGroups = state.deletedCargoGroups[action.payload.cacheId] ?? [];
        newDeletedCargoGroups.push(action.payload.id);
        state.deletedCargoGroups[action.payload.cacheId] = uniq(newDeletedCargoGroups);

        cargoGroupsSlice.caseReducers._validateCargoGroup(
          state,
          cargoGroupsSlice.actions._validateCargoGroup({
            cacheId: action.payload.cacheId,
            cargoGroupId: action.payload.id,
          })
        );
      }
    },
    _deregisterNewCargoGroup: (state, action: PayloadAction<{ cacheId: string; ids: string[] }>) => {
      const newCargoGroups = state.newCargoGroups[action.payload.cacheId] ?? [];
      state.newCargoGroups[action.payload.cacheId] = newCargoGroups.filter(
        (cargoGroup) => !action.payload.ids.includes(cargoGroup)
      );

      action.payload.ids.map((id) => {
        cargoGroupsSlice.caseReducers._validateCargoGroup(
          state,
          cargoGroupsSlice.actions._validateCargoGroup({
            cacheId: action.payload.cacheId,
            cargoGroupId: id,
          })
        );
      });
    },
    validateCargoGroups: (state, action: PayloadAction<{ cacheId: string; cargoGroupIds: Array<string | number> }>) => {
      action.payload.cargoGroupIds.forEach((cargoGroupId) => {
        cargoGroupsSlice.caseReducers._validateNestedCargoGroups(
          state,
          cargoGroupsSlice.actions._validateNestedCargoGroups({
            cacheId: action.payload.cacheId,
            cargoGroupId,
          })
        );
      });
    },
    updateCargoGroup: (
      state,
      action: PayloadAction<{
        cacheId: string;
        changes: { id: number | string; changes: Partial<NormalizedCargoGroup> };
      }>
    ) => {
      normalizedCargoGroupAdapter.updateOne(state.normalizedCargoGroups, action.payload.changes);

      cargoGroupsSlice.caseReducers._registerUpdatedCargoGroup(
        state,
        cargoGroupsSlice.actions._registerUpdatedCargoGroup({
          cacheId: action.payload.cacheId,
          id: action.payload.changes.id,
        })
      );

      cargoGroupsSlice.caseReducers._validateCargoGroup(
        state,
        cargoGroupsSlice.actions._validateCargoGroup({
          cacheId: action.payload.cacheId,
          cargoGroupId: action.payload.changes.id,
        })
      );
    },
    addCargoGroup: (
      state,
      action: PayloadAction<{
        cacheId: string;
        initialState: WithRequiredField<
          Omit<Partial<NormalizedCargoGroup>, "id">,
          "cargo_group_type" | "packaging_type"
        >;
        isTopLevelCargoGroup?: boolean;
      }>
    ) => {
      const tempId = uniqueId("temp_id_");

      // Update cargo group
      normalizedCargoGroupAdapter.addOne(state.normalizedCargoGroups, {
        count: 1,
        ...action.payload.initialState,
        id: tempId,
      });

      cargoGroupsSlice.caseReducers._validateCargoGroup(
        state,
        cargoGroupsSlice.actions._validateCargoGroup({
          cacheId: action.payload.cacheId,
          cargoGroupId: tempId,
        })
      );

      // Register new cargo group
      const newCargoGroups = state.newCargoGroups[action.payload.cacheId] ?? [];
      newCargoGroups.push(tempId);
      state.newCargoGroups[action.payload.cacheId] = uniq(newCargoGroups);

      // Register cargo group as top level cargo group
      if (action.payload.isTopLevelCargoGroup) {
        const newIsTopLevelNewCargoGroup = state.topLevelNewCargoGroups[action.payload.cacheId] ?? [];
        newIsTopLevelNewCargoGroup.push(tempId);
        state.topLevelNewCargoGroups[action.payload.cacheId] = uniq(newIsTopLevelNewCargoGroup);
      }

      // Update parent cargo group
      const relatedParentCargoGroupId = action.payload.initialState.cargo_unit_id;

      if (relatedParentCargoGroupId) {
        const relatedParentCargoGroup = normalizedCargoGroupSelectors.selectById(
          state.normalizedCargoGroups,
          relatedParentCargoGroupId
        );

        if (relatedParentCargoGroup) {
          normalizedCargoGroupAdapter.updateOne(state.normalizedCargoGroups, {
            id: relatedParentCargoGroupId,
            changes: { handling_units: [...(relatedParentCargoGroup.handling_units ?? []), tempId] },
          });
        }
      }

      // Update parent handling group
      const relatedParentHandlingGroupId = action.payload.initialState.handling_unit_id;

      if (relatedParentHandlingGroupId) {
        const relatedParentHandlingGroup = normalizedCargoGroupSelectors.selectById(
          state.normalizedCargoGroups,
          relatedParentHandlingGroupId
        );

        if (relatedParentHandlingGroup) {
          normalizedCargoGroupAdapter.updateOne(state.normalizedCargoGroups, {
            id: relatedParentHandlingGroupId,
            changes: { packing_units: [...(relatedParentHandlingGroup.packing_units ?? []), tempId] },
          });
        }
      }
    },
    removeCargoGroup: (state, action: PayloadAction<{ cacheId: string; id: string | number }>) => {
      const deletedCargoGroup = normalizedCargoGroupSelectors.selectById(
        state.normalizedCargoGroups,
        action.payload.id
      );

      if (!deletedCargoGroup) {
        return;
      }

      // Remove cargo group
      normalizedCargoGroupAdapter.removeOne(state.normalizedCargoGroups, action.payload.id);

      // This reducer will remove ids that don't exist anymore
      cargoGroupsSlice.caseReducers._validateCargoGroup(
        state,
        cargoGroupsSlice.actions._validateCargoGroup({
          cacheId: action.payload.cacheId,
          cargoGroupId: action.payload.id,
        })
      );

      // Register cargo group as deleted
      cargoGroupsSlice.caseReducers._registerDeletedCargoGroup(
        state,
        cargoGroupsSlice.actions._registerDeletedCargoGroup({ cacheId: action.payload.cacheId, id: action.payload.id })
      );

      // Update parent cargo group
      const relatedParentCargoGroupId = deletedCargoGroup.cargo_unit_id;

      if (relatedParentCargoGroupId) {
        const relatedParentCargoGroup = normalizedCargoGroupSelectors.selectById(
          state.normalizedCargoGroups,
          relatedParentCargoGroupId
        );

        if (relatedParentCargoGroup) {
          normalizedCargoGroupAdapter.updateOne(state.normalizedCargoGroups, {
            id: relatedParentCargoGroupId,
            changes: {
              handling_units: relatedParentCargoGroup.handling_units
                ?.slice()
                .filter((handlingUnitId) => handlingUnitId !== deletedCargoGroup.id),
            },
          });

          cargoGroupsSlice.caseReducers._registerUpdatedCargoGroup(
            state,
            cargoGroupsSlice.actions._registerUpdatedCargoGroup({
              cacheId: action.payload.cacheId,
              id: relatedParentCargoGroupId,
            })
          );
        }
      }

      // Update parent handling group
      const relatedParentHandlingGroupId = deletedCargoGroup.cargo_unit_id;

      if (relatedParentHandlingGroupId) {
        const relatedParentHandlingGroup = normalizedCargoGroupSelectors.selectById(
          state.normalizedCargoGroups,
          relatedParentHandlingGroupId
        );

        if (relatedParentHandlingGroup) {
          normalizedCargoGroupAdapter.updateOne(state.normalizedCargoGroups, {
            id: relatedParentHandlingGroupId,
            changes: {
              packing_units: relatedParentHandlingGroup.packing_units
                ?.slice()
                .filter((packingUnitId) => packingUnitId !== deletedCargoGroup.id),
            },
          });

          cargoGroupsSlice.caseReducers._registerUpdatedCargoGroup(
            state,
            cargoGroupsSlice.actions._registerUpdatedCargoGroup({
              cacheId: action.payload.cacheId,
              id: relatedParentHandlingGroupId,
            })
          );
        }
      }

      // Remove child packing units
      const packingUnits = deletedCargoGroup.packing_units;

      if (packingUnits) {
        packingUnits.forEach((packingUnitId) =>
          cargoGroupsSlice.caseReducers.removeCargoGroup(
            state,
            cargoGroupsSlice.actions.removeCargoGroup({
              cacheId: action.payload.cacheId,
              id: packingUnitId,
            })
          )
        );
      }

      // Remove child handling units
      const handlingUnits = deletedCargoGroup.handling_units;

      if (handlingUnits) {
        handlingUnits.forEach((handlingUnitId) =>
          cargoGroupsSlice.caseReducers.removeCargoGroup(
            state,
            cargoGroupsSlice.actions.removeCargoGroup({
              cacheId: action.payload.cacheId,
              id: handlingUnitId,
            })
          )
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addMatcher(getCargoGroupsApi.endpoints.getCargoGroups.matchFulfilled, (state, action) => {
      const cargoGroups = action.payload.data;

      const normalizedCargoGroups = normalizeCargoGroups(cargoGroups);

      normalizedCargoGroupAdapter.setMany(state.normalizedCargoGroups, normalizedCargoGroups);
    });
    /**
     * Once a new cargo group has been created we will remove it from the store.
     * It is up to the feature consumer to update the provided cargo groups
     */
    builder.addMatcher(createCargoGroupsApi.endpoints.createCargoGroups.matchFulfilled, (state, action) => {
      const createdCargoGroupIds = action.meta.arg.originalArgs.body.normalizedCargoGroups
        .map((normalizedCargoGroup) => normalizedCargoGroup.id)
        .filter((id) => typeof id === "string") as string[];

      cargoGroupsSlice.caseReducers._deregisterNewCargoGroup(
        state,
        cargoGroupsSlice.actions._deregisterNewCargoGroup({
          ids: createdCargoGroupIds,
          cacheId: action.meta.arg.originalArgs.cacheId,
        })
      );

      normalizedCargoGroupAdapter.removeMany(state.normalizedCargoGroups, createdCargoGroupIds);
    });
  },
});

const partialSliceMap = { cargoGroupsSlice };

type CargoGroupsStateType = RootState<StatesFromApisMap<typeof partialSliceMap>>;

export const selectManyCargoGroups = createSelector(
  [
    (state: CargoGroupsStateType) => state.cargoGroupsSlice.normalizedCargoGroups,
    (_state, cargoGroupIds: Array<string | number>) => cargoGroupIds,
  ],
  (normalizedCargoGroups, cargoGroupIds) =>
    cargoGroupIds
      .map((id) => normalizedCargoGroupSelectors.selectById(normalizedCargoGroups, id))
      .filter(Boolean) as NormalizedCargoGroup[]
);

export const selectNewCargoGroups = createSelector(
  [
    (state: CargoGroupsStateType) => state.cargoGroupsSlice.normalizedCargoGroups,
    (state: CargoGroupsStateType, cacheId: string) => state.cargoGroupsSlice.newCargoGroups[cacheId] ?? [],
  ],
  (normalizedCargoGroups, cargoGroupIds) =>
    cargoGroupIds
      .map((id) => normalizedCargoGroupSelectors.selectById(normalizedCargoGroups, id))
      .filter(Boolean) as NormalizedCargoGroup[]
);

export const selectUpdatedCargoGroups = createSelector(
  [
    (state: CargoGroupsStateType) => state.cargoGroupsSlice.normalizedCargoGroups,
    (state: CargoGroupsStateType, cacheId: string) => state.cargoGroupsSlice.updatedCargoGroups[cacheId] ?? [],
  ],
  (normalizedCargoGroups, cargoGroupIds) =>
    cargoGroupIds
      .map((id) => normalizedCargoGroupSelectors.selectById(normalizedCargoGroups, id))
      .filter(Boolean) as NormalizedCargoGroup[]
);

export const selectIsCacheValid = createSelector(
  [(state: CargoGroupsStateType, cacheId: string) => state.cargoGroupsSlice.validityMap[cacheId] ?? {}],
  (validityMap) =>
    Object.values(validityMap).reduce((isValid, nextValue) => isValid && nextValue, true) &&
    !!Object.values(validityMap).length
);

export const { useUpdateCargoGroup, useAddCargoGroup, useRemoveCargoGroup, useValidateCargoGroups } = usableActions(
  cargoGroupsSlice.actions
);
export default cargoGroupsSlice;
