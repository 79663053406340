import { FC, ReactElement } from "react";

import { ArrowBack } from "@material-ui/icons";
import { AppBar, Box, portexColor, Typography } from "@portex-pro/ui-components";
import getSizeStr from "utils/styles/getSizeStr";

import BackButtonView from "./BackButtonView";
import PortexLogoView from "./PortexLogoView";

interface PortexAppBarProps {
  shipperName?: string;
  useMarketingUrl?: boolean;
  useAppBar?: boolean;
  reverse?: boolean;
  hidePortexLogo?: boolean;
  backButtonTo?: string | false;
}

const PortexAppBar: FC<PortexAppBarProps> = ({
  children,
  shipperName,
  useMarketingUrl,
  useAppBar = true,
  reverse = false,
  hidePortexLogo = false,
  backButtonTo,
}): ReactElement => {
  const content = (
    <Box py={1.5} px={2}>
      <Box
        display="flex"
        flexDirection={reverse ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        {shipperName ? (
          <div className="flex space-x-2 items-center">
            {!!backButtonTo && (
              <BackButtonView
                to={backButtonTo}
                backCopy={<ArrowBack style={{ fontSize: getSizeStr(24) }} />}
                ButtonProps={{
                  startIcon: null,
                  color: "default",
                  size: "small",
                  style: { color: portexColor.grey500, minWidth: 0 },
                }}
              />
            )}
            <Typography variant="h5">
              <strong>{shipperName}</strong>
            </Typography>
          </div>
        ) : children ? (
          children
        ) : null}
        {!hidePortexLogo && <PortexLogoView linkTo={useMarketingUrl ? "marketing" : "default"} />}
      </Box>
    </Box>
  );

  return useAppBar ? <AppBar style={{ boxShadow: `0 1px 0 ${portexColor.grey300}` }}>{content}</AppBar> : content;
};

export default PortexAppBar;
