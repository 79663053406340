import { VFC } from "react";

import { TextInput } from "@portex-pro/ui-components";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import { getCargoGroupEquipmentString } from "features/cargo-groups/utils/getCargoGroupEquipmentString";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import { LineItem, State } from "../store/brokerQuoteSubmissionSlice";

interface ContainerChargesViewProps {
  lineItems: State["lineItems"];
  onChangeLineItems: (action: { index: number; changes: Partial<Omit<LineItem, "cargoGroup" | "type">> }) => void;
}

const ContainerChargesView: VFC<ContainerChargesViewProps> = (props) => {
  const { lineItems, onChangeLineItems } = props;
  const { t } = useTranslation(["common", "broker"]);

  return (
    <div className="w-full grid grid-cols-[30%_1fr_1fr_1fr_1fr] border border-grey-300 rounded bg-white">
      <div className="border-b py-3 px-4">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("broker:item")}
        </Text>
      </div>
      <div className="border-b py-3 px-4">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("broker:charge")}
        </Text>
      </div>
      <div className="border-b py-3 px-4">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("broker:units")}
        </Text>
      </div>
      <div className="border-b py-3 px-4">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("broker:quantity")}
        </Text>
      </div>
      <div className="border-b py-3 px-4">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("broker:total")}
        </Text>
      </div>

      <div className="col-span-5 py-3 px-4 bg-grey-75 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("common:freightCharges")}
        </Text>
      </div>

      {lineItems.map((lineItem, index) => {
        if (lineItem.type === "FREIGHT_CHARGE") {
          return (
            <div className="grid grid-cols-subgrid col-span-5 even:bg-zebra py-3 px-4 gap-x-4">
              <div className="flex flex-col space-y-1">
                <Text size="small" weight="bold">
                  {t("common:containerCharges", { value: getCargoGroupEquipmentString(lineItem.cargoGroup, t) })}
                </Text>
                <Text size="small">{t("broker:containerCharges_desc")}</Text>
              </div>
              <div className="flex flex-col">
                <PositiveNumberInput
                  startIcon={<>$</>}
                  value={lineItem.amount}
                  required
                  onChange={(value) => onChangeLineItems({ index, changes: { amount: value } })}
                />
              </div>
              <div className="flex flex-col">
                <TextInput value={t("common:perContainer")} disabled />
              </div>
              <div className="flex flex-col">
                <PositiveNumberInput value={lineItem.cargoGroup.count} disabled />
              </div>
              <div className="flex flex-col items-center">
                <Text size="small">{formatUSD(lineItem.amount ?? 0)}</Text>
              </div>
            </div>
          );
        }

        return (
          <div className="grid grid-cols-subgrid col-span-5 even:bg-zebra py-3 px-4 gap-x-4">
            <div className="flex flex-col space-y-1">
              <Text size="small" weight="bold">
                {t("broker:chargesStep.additionalCharges")}
              </Text>
              <Text size="small">{t("broker:additionalCharges_desc")}</Text>
            </div>
            <div className="flex flex-col">
              <PositiveNumberInput
                startIcon={<>$</>}
                value={lineItem.amount}
                required
                onChange={(value) => onChangeLineItems({ index, changes: { amount: value } })}
              />
            </div>
            <div className="flex flex-col">
              <TextInput value={t("broker:chargesStep.perShipment")} disabled />
            </div>
            <div className="flex flex-col"></div>
            <div className="flex flex-col items-center">
              <Text size="small">{formatUSD(lineItem.amount ?? 0)}</Text>
            </div>
          </div>
        );
      })}

      <div className="grid grid-cols-subgrid col-span-5 even:bg-zebra pt-3 px-4 pb-5 gap-x-4">
        <div className="flex flex-col space-y-1">
          <Text size="small" weight="bold">
            {t("broker:total")}
          </Text>
          <Text size="small">{t("broker:total_desc")}</Text>
        </div>
        <div />
        <div />
        <div />
        <div className="flex flex-col items-center">
          {formatUSD(lineItems.reduce((total, lineItem) => total + (lineItem.amount ?? 0), 0))}
        </div>
      </div>
    </div>
  );
};

export default ContainerChargesView;
