import { VFC } from "react";

import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import FiltersAppBar from "utils/layout/FiltersAppBar";

import { BrokerInsightsIndexFilterState } from "../store/brokerInsightsIndexFilterSlice";

interface BrokerInsightsFilterBarViewProps {
  filters?: JSX.Element;
  transportationMode: TransportationMode;
  searchQuery: string;
  lastRefreshedAt?: string;
  isLoading: boolean;

  onChangeFilter: (changes: Partial<BrokerInsightsIndexFilterState>) => void;
  onRefresh: () => void;
}

const BrokerInsightsFilterBarView: VFC<BrokerInsightsFilterBarViewProps> = ({
  filters,
  transportationMode,
  searchQuery,
  lastRefreshedAt,
  isLoading,
  onChangeFilter,
  onRefresh,
}) => {
  return (
    <FiltersAppBar className="shadow">
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          <ModeButtonGroup
            value={transportationMode}
            onChange={(transportationMode) => onChangeFilter({ transportationMode })}
            includeModes={[TransportationMode.INTERMODAL]}
            excludeModes={[TransportationMode.All]}
            disabledModes={[TransportationMode.Trucking]}
          />
          <SimpleSearchView
            search={searchQuery}
            onChange={(searchQuery) => onChangeFilter({ searchQuery })}
            placeholder={"Search"}
          />
          {filters}
        </div>
        <div className="whitespace-nowrap flex grow items-center justify-end">
          {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
          <div>
            <RefetchButton loading={isLoading} onClick={onRefresh} />
          </div>
        </div>
      </div>
    </FiltersAppBar>
  );
};

export default BrokerInsightsFilterBarView;
