import { FC } from "react";

import useStaticStores from "hooks/store/useStaticStores";
import { useUserContext } from "hooks/useUserContext";
import { StringParam, useQueryParam } from "use-query-params";

interface LoggedOutProps {
  withUut?: boolean;
}

const LoggedOut: FC<LoggedOutProps> = ({ children, withUut }) => {
  const { isLoading, user, auth0User } = useUserContext();
  const unclaimedUserToken = useStaticStores((store) => store.authSlice.unclaimedUserToken);
  const [uut] = useQueryParam("uut", StringParam);

  if (!children) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (!!user) {
    return null;
  }
  if (!!auth0User) {
    return null;
  }

  if (withUut === true && !unclaimedUserToken && !uut) {
    return null;
  } else if (withUut === false && (!!unclaimedUserToken || !!uut)) {
    return null;
  }

  return <>{children}</>;
};

export default LoggedOut;
