import { configureSlices } from "utils/store/configureSlices";

import brokerShippersIndexFilterSlice from "./brokerShippersIndexFilterSlice";

const slicesMap = {
  brokerShippersIndexFilterSlice,
};

export const { useSlices: useBrokerShippersIndexSlices, useSliceSelector: useBrokerShippersIndexSliceSelector } =
  configureSlices(slicesMap);
