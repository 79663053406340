import countries from "country-list";
import orderBy from "lodash/orderBy";

const UNITED_STATES_COUNTRY_NAME = "United States";
countries.overwrite([
  {
    code: "US",
    name: UNITED_STATES_COUNTRY_NAME,
  },
  {
    code: "GB",
    name: "United Kingdom",
  },
]);

const DOMESTIC_CODES = new Set(["US", "CA", "MX"]);
const PRIORITIZED_CODES = new Set(["US", "CA", "MX", "CN", "SG", "PL", "GB"]);
const PRIORITIZED_COUNTRIES: countries.Country[] = Array.from(PRIORITIZED_CODES).map((code) => ({
  code,
  name: countries.getName(code) ?? "",
}));

export const ALL_COUNTRIES_ASC = orderBy(countries.getData(), (country) => country.name, "asc");
const ALL_COUNTRIES_SORTED = PRIORITIZED_COUNTRIES.concat(
  ALL_COUNTRIES_ASC.filter((country) => !PRIORITIZED_CODES.has(country.code))
);

export const mapCountryToCountryCode = ALL_COUNTRIES_ASC.reduce<Record<string, string>>((map, country) => {
  map[country.name] = country.code;
  return map;
}, {});

export const COUNTRY_OPTIONS_DOMESTIC = ALL_COUNTRIES_SORTED.filter((country) => DOMESTIC_CODES.has(country.code)).map(
  ({ name }) => name
);
export const COUNTRY_OPTIONS_INTERNATIONAL = ALL_COUNTRIES_SORTED.map(({ name }) => name);
export const DEFAULT_COUNTRY = UNITED_STATES_COUNTRY_NAME;
