import { configureSlices } from "utils/store/configureSlices";

import brokerInsightsIndexFilterSlice from "./brokerInsightsIndexFilterSlice";

const slicesMap = {
  brokerInsightsIndexFilterSlice,
};

export const { useSlices: useBrokerInsightsIndexSlices, useSliceSelector: useBrokerInsightsIndexSliceSelector } =
  configureSlices(slicesMap);
