import { TFunction } from "react-i18next";

import { CargoGroup, NormalizedCargoGroup } from "../api/types";

export const getCargoGroupEquipmentString = (
  cargoGroup: CargoGroup | NormalizedCargoGroup,
  t: TFunction<["common"]>
): string => {
  const temps = [cargoGroup.min_temp, cargoGroup.max_temp].filter((temp) => temp !== undefined && temp !== null);

  return [
    cargoGroup.count,
    [
      cargoGroup.equipment_size ? t(`common:trailerSizes.${cargoGroup.equipment_size}`) : "",
      cargoGroup.equipment_type ? t(`common:trailerTypes.${cargoGroup.equipment_type}`) : "",
      temps.length > 0
        ? t("common:inParenthesis", {
            val: t("common:temp", {
              count: temps.length,
              value: temps[0],
              min: temps[0],
              max: temps[1],
              context: cargoGroup.requires_genset ? "withGenset" : undefined,
              scale: "F",
            }),
          })
        : "",
    ]
      .filter(Boolean)
      .join(" "),
  ]
    .filter(Boolean)
    .join(" x ");
};
