import { VFC } from "react";

import noop from "lodash/noop";

import BrokerBidsIndexTableView from "../views/BrokerBidsIndexTableView";

const BrokerBidsIndexTableContainer: VFC = () => {
  return (
    <BrokerBidsIndexTableView
      items={[]}
      paginationOptions={{
        next: noop,
        prev: noop,
        page: 0,
        total: 0,
        setTake: noop,
        take: 25,
      }}
      isLoading={false}
    />
  );
};

export default BrokerBidsIndexTableContainer;
