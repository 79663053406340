import { useState, VFC } from "react";

import { Add, Close, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Button, Checkbox, Collapse, FormControlLabel, NumberInput } from "@portex-pro/ui-components";
import { ShipmentPackagingType } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

import { NormalizedCargoGroup } from "../api/types/NormalizedCargoGroup";
import { handlingTypesList, palletTypes } from "../const";

interface HandlingUnitsViewProps {
  cargoGroups: NormalizedCargoGroup[];

  onAddHandlingUnit: () => void;
  onRemoveHandlingUnit: (id: number | string) => void;
  onChangeHandlingUnit: (id: string | number, changes: Partial<Omit<NormalizedCargoGroup, "id">>) => void;
  initialIsOpen?: boolean;

  renderPackingUnit: VFC<{ cargoGroupIds: NormalizedCargoGroup["id"][]; parentId: string | number }>;
}

const HandlingUnitsView: VFC<HandlingUnitsViewProps> = (props) => {
  const {
    cargoGroups,
    onAddHandlingUnit,
    onChangeHandlingUnit,
    onRemoveHandlingUnit,
    initialIsOpen = true,
    renderPackingUnit: PackingUnitsView,
  } = props;
  const { t } = useTranslation(["common", "shipper"]);
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  return (
    <div className="grid grid-cols-[30px_1fr_1fr] p-2">
      <div className="col-span-full flex flex-row items-center cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <Text size="medium" weight="bold">
          {t("common:handlingUnits")}
        </Text>
        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      <Collapse in={isOpen} className="col-span-full">
        <div className="grid grid-cols-[30px_1fr_1fr]">
          {cargoGroups && !!cargoGroups.length && (
            <>
              <div />
              <div className="pt-2">
                <Text size="medium" weight="bold" required>
                  {t("common:handlingType")}
                </Text>
              </div>
              <div className="pt-2">
                <Text size="medium" weight="bold" required>
                  {t("common:handlingCount")}
                </Text>
              </div>
            </>
          )}
          {cargoGroups.map((cargoGroup) => (
            <>
              <div className="flex items-center content-center cursor-pointer">
                <Close onClick={() => onRemoveHandlingUnit(cargoGroup.id)} />
              </div>
              <div className="w-full flex items-center content-center py-1 pr-2">
                <FormSelectView
                  items={handlingTypesList as unknown as ShipmentPackagingType[]}
                  getItemCopy={(item) => t(`shipper:packingTypeMap.${item as ShipmentPackagingType}`)}
                  value={cargoGroup.packaging_type as unknown as ShipmentPackagingType}
                  fullWidth
                  formControlProps={{ fullWidth: true }}
                  onChange={(value) =>
                    onChangeHandlingUnit(cargoGroup.id, { packaging_type: value as unknown as ShipmentPackagingType })
                  }
                />
              </div>
              <div className="w-full flex items-center content-center py-1">
                <NumberInput
                  value={cargoGroup.count}
                  fullWidth
                  disableError
                  onChange={(event) => onChangeHandlingUnit(cargoGroup.id, { count: Number(event.target.value) })}
                />
              </div>
              {palletTypes.includes(cargoGroup.packaging_type) && (
                <>
                  <div />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cargoGroup.is_stackable}
                          onChange={(_, checked) => onChangeHandlingUnit(cargoGroup.id, { is_stackable: checked })}
                        />
                      }
                      label={t("shipper:packageGroupView.stackable", { type: t("shipper:bids.fieldNames.pallets") })}
                    />
                  </div>
                  <div />
                </>
              )}

              <div />
              <div className="col-span-2">
                <PackingUnitsView cargoGroupIds={cargoGroup.packing_units ?? []} parentId={cargoGroup.id} />
              </div>
            </>
          ))}
          <div className="col-span-full">
            <Button color="primary" startIcon={<Add />} onClick={() => onAddHandlingUnit()}>
              {t("common:addHandlingUnit")}
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default HandlingUnitsView;
