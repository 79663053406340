import { useState } from "react";

import getShipperLoadsApi from "api/rest/loads/getShipperLoadsApi";
import { getShipperQuoteRequestApi } from "api/rest/quote-requests";
import { useUpdateShipperQuoteRequestMutation } from "api/rest/quote-requests/updateShipperQuoteRequest";
import { useUpdateQuoteMutation } from "api/rest/quotes/updateQuoteApi";
import { getShipmentSourcesApi } from "api/rest/shipments";
import shipmentsApi from "api/rest/shipments/shipmentsApi";
import FilesControl, { OnMultiUploadSuccessCallback } from "components/file-uploads/FilesControl";
import withAsync from "components/withAsync";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import BookNowView from "pages/shipper/pages/quote-details-page/components/components/BookNowView";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Sentry } from "sentry";

import useLoadQuoteRequestAndQuote from "../../hooks/quotes/useLoadQuoteRequestAndQuote";

const BookQuoteContainer = withAsync({
  useHook: useLoadQuoteRequestAndQuote,
  Component: ({ loadedData: { quote, quoteRequest } }) => {
    const [bookingNotes, setBookingNotes] = useState(quote.booking_notes ?? "");
    const [isConfirmed, setIsConfirmed] = useState(true);
    const [updateQuoteRequest, updateQuoteRequestMutation] = useUpdateShipperQuoteRequestMutation();
    const [updateQuote, updateQuoteMutation] = useUpdateQuoteMutation();
    const { t } = useTranslation(["common"]);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const bookNow = async () => {
      if (!isConfirmed) {
        return;
      }

      try {
        await updateQuoteRequest({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: { status: "BOOKED", bookedQuoteId: quote._quote_id },
        });
        dispatch(getShipmentSourcesApi.util.invalidateTags(["SOURCES"]));
        dispatch(getShipperQuoteRequestApi.util.invalidateTags(["QUOTE_REQUESTS"]));
        dispatch(shipmentsApi.util.invalidateTags(["shipment"]));
        dispatch(getShipperLoadsApi.util.invalidateTags(["LOADS"]));
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    const uploadFiles: OnMultiUploadSuccessCallback = async (uploadResults) => {
      const fileIds = uploadResults.map((result) => result.fileId);

      try {
        await updateQuote({
          // @ts-expect-error The typing here is bugging out
          body: { files: fileIds, quoteType: quote.mode },
          urlParams: { quoteId: quote._quote_id },
        }).unwrap();
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    const disableBookNow =
      quote.status === "BOOKED" ||
      quoteRequest.status === "BOOKED" ||
      updateQuoteMutation.isLoading ||
      updateQuoteRequestMutation.isLoading ||
      quoteRequest.status === "CLOSED" ||
      quoteRequest.status === "CANCELED";

    return (
      <FilesControl
        fileIds={quote.file_ids}
        dropzoneOptions={{
          disabled: disableBookNow,
        }}
        onMultiUploadSuccess={uploadFiles}
      >
        <BookNowView
          mode={quoteRequest.mode}
          bookingNotes={bookingNotes}
          brokerName={quote.company_name ?? quote.submitter_email ?? ""}
          canceledAt={"@todo"}
          closedAt={"@todo"}
          confirmed={isConfirmed}
          disableBookNow={disableBookNow}
          isExpired={
            "valid_until" in quote && !!quote.valid_until && DateTime.now() > DateTime.fromISO(quote.valid_until)
          }
          isQuoteRequestBooked={quoteRequest.status === "BOOKED"}
          isQuoteRequestCanceled={quoteRequest.status === "CANCELED"}
          isQuoteRequestClosed={quoteRequest.status === "CLOSED"}
          onChangeBookingNotes={function (notes: string): void {
            setBookingNotes(notes);
          }}
          onChangeConfirmed={function (confirmed: boolean): void {
            setIsConfirmed(confirmed);
          }}
          onClickBookNow={bookNow}
          quoteBooked={quote.status === "BOOKED"}
          quoteCanceled={quote.status === "CANCELED"}
          quoteTotal={quote.total_amount}
          shipmentId={quoteRequest.shipment_id}
        />
      </FilesControl>
    );
  },
});

export default BookQuoteContainer;
