import { ReactElement, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Paper, SvgPortexLogo, Typography } from "@portex-pro/ui-components";
import { useRefreshTokenForLinkMutation } from "api/rest/users/refreshTokenForLinkApi";
import { useSendClaimUserEmailMutation } from "api/rest/users/sendClaimUserEmailApi";
import Text from "components/Text";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { useQueryParam, StringParam, BooleanParam } from "use-query-params";
import { useEffectOnce } from "usehooks-ts";

const UserTokenExpired = (): ReactElement => {
  const enableUserClaimFlow = useLDFlag("enableUserClaimFlow");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [sentQueryParam] = useQueryParam("sent", BooleanParam);
  const [claimEmailSent, setClaimEmailSent] = useState(!!sentQueryParam);
  const [refreshLinkEmailSent, setRefreshLinkEmailSent] = useState(!!sentQueryParam);
  const [returnTo] = useQueryParam("returnTo", StringParam);
  const { logout } = useAuth0();

  const [refreshTokenForLink] = useRefreshTokenForLinkMutation();
  const [sendClaimUserEmailMutation, { isLoading }] = useSendClaimUserEmailMutation();

  const buildLogoutReturnToUrl = () => {
    const url = new URL(location.href);
    url.searchParams.set("sent", "1");
    return url.href;
  };

  const sendClaimEmail = async () => {
    if (claimEmailSent || isLoading) {
      return;
    }

    try {
      await sendClaimUserEmailMutation({ queryParams: { returnTo: undefined } }).unwrap();
      setClaimEmailSent(true);
      enqueueSnackbar(t("userTokenExpired_emailSent"), {
        variant: "success",
        persist: true,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      logout({ returnTo: buildLogoutReturnToUrl() });
    } catch (e) {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  const sendRefreshLinkEmail = async () => {
    if (!returnTo || refreshLinkEmailSent) {
      return;
    }

    try {
      await refreshTokenForLink({ body: { returnTo } });
      setRefreshLinkEmailSent(true);
    } catch (e) {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
      Sentry.captureException(e);
    }
  };

  useEffectOnce(() => {
    sendRefreshLinkEmail();
  });

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper elevation={24} style={{ maxWidth: "40%" }}>
        <Box p={2.5}>
          <div className="flex justify-center">
            <SvgPortexLogo width={135} height={40} />
          </div>
          <div className="pt-6 flex justify-center">
            <Text size="large" weight="bold">
              {t("userTokenExpired_title")}
            </Text>
          </div>
          <div className="pt-4 text-center">
            <Text size="medium">{t("userTokenExpired_description")}</Text>
          </div>
          <div className="pt-6 flex justify-center">
            <Text size="large" weight="bold">
              {t("userTokenExpired_why_title")}
            </Text>
          </div>
          <div className="pt-4">
            <Text size="medium">{t("userTokenExpired_why_description")}</Text>
          </div>
          <div className="pt-6 flex justify-center">
            <Text size="large" weight="bold">
              {t("userTokenExpired_claim_title")}
            </Text>
          </div>
          <div className="pt-4">
            <Text size="medium">{t("userTokenExpired_claim_description")}</Text>
          </div>
          {!!enableUserClaimFlow && (
            <div className="pt-1 flex justify-center">
              <Button onClick={sendClaimEmail}>
                <Typography color="primary" variant="subtitle1" align="center">
                  {t("userTokenExpired_claim_button")}
                </Typography>
              </Button>
            </div>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default UserTokenExpired;
