import { useState, VFC } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, TextInput } from "@portex-pro/ui-components";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import { useRequestClaimMutation } from "api/rest/users/requestClaim";
import LoggedIn from "components/auth/LoggedIn";
import LoggedOut from "components/auth/LoggedOut";
import useLDFlag from "hooks/useLDFlag";
import { useSubmitterStore } from "pages/shipper/pages/quotes/hooks/useSubmitterStore";
import { Trans, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { validateEmail } from "utils/validateEmail";

const BrokerSignupPage: VFC = () => {
  const { t } = useTranslation(["common", "broker"]);
  const auth0 = useAuth0();
  const [claimRequestedQueryParam] = useQueryParam("claimRequested", BooleanParam);
  const [claimEmailQueryParam] = useQueryParam("claimEmail", StringParam);
  const getCurrentUserQuery = useGetCurrentUserQuery();
  const user = getCurrentUserQuery.data?.data.user;
  const { submitterEmail } = useSubmitterStore();
  const [email, setEmail] = useState<string>(claimEmailQueryParam ?? user?.email ?? submitterEmail ?? "");
  const [claimRequested, setClaimRequested] = useState<boolean>(!!claimRequestedQueryParam);
  const [requestClaim, requestClaimMutation] = useRequestClaimMutation();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  const buildLogoutReturnToUrl = () => {
    const url = new URL(location.href);
    url.searchParams.set("claimRequested", "1");
    url.searchParams.set("claimEmail", email);
    return url.href;
  };

  if (!enableBrokerAppBar) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <LoggedIn as={["broker", "claimed"]}>
        <Redirect to="/broker" />
      </LoggedIn>
      <LoggedIn as={["shipper"]}>
        <Redirect to="/shipper" />
      </LoggedIn>
      <div className="flex h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="p-10 rounded-lg lg:mx-auto lg:w-full lg:max-w-lg bg-white shadow-lg space-y-5">
          <div className="text-center font-bold text-large pb-3">
            <LoggedIn>{t("broker:claimAccount")}</LoggedIn>
            <LoggedOut withUut={true}>{t("broker:claimAccount")}</LoggedOut>
            <LoggedOut withUut={false}>{t("broker:signUp")}</LoggedOut>
          </div>
          <span className="text-medium">
            <LoggedIn>{t("broker:claimInstructions")}</LoggedIn>
            <LoggedOut withUut={true}>{t("broker:claimInstructions")}</LoggedOut>
            <LoggedOut withUut={false}>{t("broker:signupInstructions")}</LoggedOut>
          </span>
          <TextInput
            value={email}
            onChange={(event) => setEmail(event.target.value ?? "")}
            disabled={requestClaimMutation.isLoading || requestClaimMutation.isError || !!claimRequested}
            fullWidth
            highlight={!!email && !validateEmail(email)}
            label={t("common:emailAddress")}
            placeholder={t("broker:signupEmailPlaceholder")}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={
              !validateEmail(email) ||
              requestClaimMutation.isLoading ||
              requestClaimMutation.isError ||
              !!claimRequested
            }
            loading={requestClaimMutation.isLoading}
            onClick={async () => {
              await requestClaim({ body: { email } }).unwrap();
              setClaimRequested(true);
              if (auth0.isAuthenticated && auth0.user?.email !== email) {
                auth0.logout({ returnTo: buildLogoutReturnToUrl() });
              }
            }}
          >
            {t("broker:signupConfirm")}
          </Button>
          {claimRequested && (
            <div className="text-green-500 text-medium italic">
              <Trans ns="broker" i18nKey="signupRequestSuccess" values={{ email }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BrokerSignupPage;
