import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";

import { QuoteRequest } from "../quote-requests";
import { mapQuoteRequestsToShipperTemplates } from "./mapQuoteRequestsToShipperTemplates";

type UrlParams = {
  templateId: number;
};

type Body = {
  name?: string;
};

type QueryArgs = {
  urlParams: UrlParams;
  body: Body;
};

type ResponseType = ApiResponse<{
  id: number;
  name: string;
}>;

module.hot?.accept();

export const updateQuoteRequestTemplateApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["TEMPLATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      __DEPRECATED__updateQuoteRequestTemplate: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams: { templateId }, body }) => ({
          method: "PUT",
          url: `shipper/quote-requests/templates/${templateId}`,
          body,
        }),
        invalidatesTags: ["TEMPLATES"],
      }),
      updateQuoteRequestTemplate: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams, body }) => ({
          method: "PUT",
          url: `shipper/quote-requests/${urlParams.templateId}`,
          body: {
            templateName: body.name,
          },
        }),
        invalidatesTags: ["TEMPLATES"],
        transformResponse: (apiResponse: ApiResponse<{ quoteRequest: QuoteRequest }>) => {
          return { data: mapQuoteRequestsToShipperTemplates([apiResponse.data.quoteRequest])[0] };
        },
      }),
    }),
  });

export const { use__DEPRECATED__updateQuoteRequestTemplateMutation, useUpdateQuoteRequestTemplateMutation } =
  updateQuoteRequestTemplateApi;
