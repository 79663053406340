import { baseRestApi } from "api/rest/baseRestApi";

import { ShipperAddress } from "./types";

const API_ROOT = "/shipper/addresses/import";
module.hot?.accept();

type BodyParams = { addresses: Array<Partial<ShipperAddress>> };

type QueryArgs = { body: BodyParams };

export type Response = void;

const importAddressesApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["addresses", "address"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    importAddresses: builder.mutation<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}`,
        method: "POST",
        body: params.body,
      }),
      invalidatesTags: ["addresses"],
    }),
  }),
});

export default importAddressesApi;

export const { useImportAddressesMutation } = importAddressesApi;
