import { VFC } from "react";

import noop from "lodash/noop";
import { DateTime } from "luxon";

import { brokerRequestsIndexFilterActions } from "../store/brokerRequestsIndexFilterSlice";
import { useBrokerRequestsIndexSliceSelector } from "../store/brokerRequestsIndexStore";
import BrokerRequestsFilterBarView from "../views/BrokerRequestsFilterBarView";

const BrokerLoadsFilterBarContainer: VFC = () => {
  const { searchQuery, transportationMode } = useBrokerRequestsIndexSliceSelector(
    (store) => store.brokerRequestsIndexFilterSlice
  );
  const setFilterState = brokerRequestsIndexFilterActions.useSetFilterState();
  const now = Date.now();
  const lastRefreshedAt = DateTime.fromMillis(now).toLocaleString(DateTime.TIME_WITH_SECONDS);

  return (
    <BrokerRequestsFilterBarView
      isLoading={false}
      onChangeFilter={setFilterState}
      onRefresh={noop}
      searchQuery={searchQuery}
      transportationMode={transportationMode}
      lastRefreshedAt={lastRefreshedAt}
    />
  );
};

export default BrokerLoadsFilterBarContainer;
