import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import usableActions from "utils/store/usableActions";

export type BrokerBidsIndexFilterState = {
  transportationMode: TransportationMode;
  searchQuery: string;
};

const initialState: BrokerBidsIndexFilterState = {
  transportationMode: TransportationMode.All,
  searchQuery: "",
};

const slice = createSlice({
  name: "brokerBidsIndexFilterSlice",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<BrokerBidsIndexFilterState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const brokerBidsIndexFilterActions = usableActions(slice.actions);
export default slice;
