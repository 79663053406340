import { configureSlices } from "utils/store/configureSlices";

import brokerBidsIndexFilterSlice from "./brokerBidsIndexFilterSlice";

const slicesMap = {
  brokerBidsIndexFilterSlice,
};

export const { useSlices: useBrokerBidsIndexSlices, useSliceSelector: useBrokerBidsIndexSliceSelector } =
  configureSlices(slicesMap);
