import { VFC } from "react";

import { ArrowBack, MoreVert } from "@material-ui/icons";
import { portexColor } from "@portex-pro/ui-components";
import BackButtonView from "components/BackButtonView";
import LoadTypeChip from "components/LoadTypeChip";
import PortexLogoView from "components/PortexLogoView";
import RowListView from "components/RowListView";
import SideDrawerButtonView from "components/SideDrawerButtonView";
import { useTranslation } from "react-i18next";
import { ModeShipments } from "types/Mode";
import getSizeStr from "utils/styles/getSizeStr";

interface ShipmentHeaderViewProps {
  hidePortexLogo?: boolean;
  backButtonTo?: string | false;
  portexId: string;
  referenceNumber?: string | null | undefined;
  lane: string;
  mode: ModeShipments;
  shipper: string;
  equipment: string;

  onClickViewDetails?: () => void;
  rightContent?: JSX.Element;
}

const ShipmentHeaderView: VFC<ShipmentHeaderViewProps> = ({
  hidePortexLogo = false,
  backButtonTo,
  portexId,
  referenceNumber,
  lane,
  mode,
  shipper,
  equipment,

  onClickViewDetails,
  rightContent = null,
}) => {
  const { t } = useTranslation("loads");
  return (
    <div className="pl-6 p-2 pr-0 w-full flex justify-between">
      <div className="flex gap-5 items-center">
        {!!backButtonTo && (
          <BackButtonView
            to={backButtonTo}
            backCopy={<ArrowBack style={{ fontSize: getSizeStr(24) }} />}
            ButtonProps={{
              startIcon: null,
              color: "default",
              size: "large",
              style: { color: portexColor.grey500, minWidth: 0 },
            }}
          />
        )}
        {!hidePortexLogo && <PortexLogoView color="blue" linkTo="marketing" />}
        <RowListView
          BoxProps={{ className: "gap-7 2xl:gap-10" }}
          rowBoxProps={{ alignItems: "start" }}
          rows={[
            { label: t("shipmentHeader_shipment_label"), value: portexId, hide: !portexId },
            { label: t("shipmentHeader_reference_label"), value: referenceNumber ?? "", hide: !referenceNumber },
            { label: t("shipmentHeader_lane_label"), value: lane, hide: !lane },
            {
              label: t("shipmentHeader_mode_label"),
              value: <LoadTypeChip mode={mode} statusProps={{ style: { marginTop: -5, padding: 0 } }} />,
            },
            { label: t("shipmentHeader_shipper_label"), value: shipper, hide: !shipper },
            { label: t("shipmentHeader_equipment_label"), value: equipment, hide: !equipment },
          ]}
        />
      </div>

      {rightContent}

      {onClickViewDetails && (
        <div className="self-center flex-shrink-0 xl:w-[220px]">
          <SideDrawerButtonView fullWidth endIcon={<MoreVert fontSize="small" />} onClick={onClickViewDetails}>
            {t("shipmentHeader_viewDetailsButton_label")}
          </SideDrawerButtonView>
        </div>
      )}
    </div>
  );
};

export default ShipmentHeaderView;
