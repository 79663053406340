import { ALL_COUNTRIES_ASC, mapCountryToCountryCode } from "constants/countries";

import { VFC } from "react";

import { AppBar, Button } from "@portex-pro/ui-components";
import { useImportAddressesMutation } from "api/rest/address/importAddressesApi";
import { ShipperAddress } from "api/rest/address/types";
import { AddressType } from "api/types/generated-types";
import SimpleSearchView from "components/SimpleSearchView";
import { IDeveloperField } from "dromo-uploader-js";
import { useDromoUploader } from "features/dromo/useDromoUploader";
import useLDFlag from "hooks/useLDFlag";
import chunk from "lodash/chunk";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import AddressTypeButtonGroup from "views/addresses/AddressTypeButtonGroup";

import { useSetState } from "../store/locationsSlice";
import { useLocationsSliceSelector } from "../store/locationsStore";

const mapTypeToFilters: Record<AddressType, AddressType[]> = {
  [AddressType.Street]: [AddressType.Street, AddressType.Unknown],
  [AddressType.Unknown]: [AddressType.Street, AddressType.Unknown],
  [AddressType.Airport]: [AddressType.Airport],
  [AddressType.Railhead]: [AddressType.Railhead],
  [AddressType.Seaport]: [AddressType.Seaport],
};

const countryNameSelect: IDeveloperField["selectOptions"] = ALL_COUNTRIES_ASC.map((country) => ({
  label: country.name,
  value: country.name,
}));

const LocationsSearchBar: VFC = () => {
  const { t } = useTranslation(["locationsV2", "common"]);
  const setLocationsState = useSetState();
  const type = useLocationsSliceSelector((state) => state.locationsSlice.type);
  const search = useLocationsSliceSelector((state) => state.locationsSlice.search);
  const [importAddresses] = useImportAddressesMutation();
  const { enqueueSnackbar } = useSnackbar();
  const enableImportAddresses = useLDFlag("enableImportAddresses");

  const upload = async (results: Array<unknown>) => {
    try {
      // @ts-expect-error This is fine for now
      const locationsData = results.map(({ contacts, ...result }) => {
        const address_contacts: Array<{
          first_name?: string;
          last_name?: string;
          email?: string;
          phone_number?: string;
        }> = [];
        chunk((contacts as string).replaceAll(" ", "").split(","), 4).forEach((parsedContactDetails) => {
          const addressContact: { first_name?: string; last_name?: string; email?: string; phone_number?: string } = {};

          (addressContact.first_name = parsedContactDetails[0] ?? undefined),
            (addressContact.last_name = parsedContactDetails[1] ?? undefined),
            (addressContact.email = parsedContactDetails[2] ?? undefined),
            (addressContact.phone_number = parsedContactDetails[3] ?? undefined),
            address_contacts.push(addressContact);
        });
        return {
          ...result,
          country_code: mapCountryToCountryCode[result.county_name],
          type: AddressType.Street,
          address_contacts,
        };
      });

      await importAddresses({ body: { addresses: locationsData as unknown as ShipperAddress[] } });
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  const { dromoUploader } = useDromoUploader({
    onResults: upload,
    settings: {
      importIdentifier: "locations-upload",
      maxRecords: 100,
    },
    fields: [
      {
        label: t("locationsV2:formNameLabel"),
        key: "name",
        type: "string",
      },
      {
        label: t("locationsV2:formStreetAddressOneLabel"),
        key: "address_1",
        type: "string",
      },
      {
        label: t("locationsV2:formStreetAddressTwoLabel"),
        key: "address_2",
        type: "string",
      },
      {
        label: t("locationsV2:formCityLabel"),
        key: "city",
        type: "string",
        validators: [{ validate: "required" }],
      },
      {
        label: t("locationsV2:formStateLabel"),
        key: "province_name",
        type: "string",
        validators: [{ validate: "required" }],
      },
      {
        label: t("locationsV2:formZipLabel"),
        key: "zip",
        type: "string",
        validators: [{ validate: "required" }],
      },
      {
        label: t("locationsV2:formCountryLabel"),
        key: "country_name",
        type: "select",
        validators: [{ validate: "required" }],
        selectOptions: countryNameSelect,
      },
      {
        label: t("locationsV2:contactsLabel"),
        key: "contacts",
        type: "string",
        description: t("locationsV2:contactsUpload_desc"),
      },
    ],
  });

  const handleChange = (selectedType: AddressType): void => {
    if (type === selectedType) {
      setLocationsState({ type: undefined, typeFilters: undefined });
      return;
    }

    setLocationsState({ type: selectedType, typeFilters: mapTypeToFilters[selectedType] });
  };

  return (
    <AppBar>
      <div className="bg-white">
        <div className="flex pl-4">
          <div className="flex flex-wrap py-2 gap-x-4 gap-y-2 items-center">
            <AddressTypeButtonGroup value={type} onChange={handleChange} />
            <SimpleSearchView
              search={search}
              onChange={(search) => setLocationsState({ search })}
              placeholder={t("locationsV2:searchPlaceholder")}
            />
          </div>
          <div className="flex items-center justify-end flex-grow pr-4 whitespace-nowrap space-x-3">
            {enableImportAddresses && (
              <Button variant="outlined" onClick={async () => await dromoUploader.open()}>
                {t("locationsV2:bulkUpload")}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setLocationsState({ isAddressCreationOpen: true })}
            >
              {t("locationsV2:addLocationButton")}
            </Button>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default LocationsSearchBar;
