import { TypedDocumentNode, gql, useMutation } from "@apollo/client";
import { useCreateQuoteRequestTemplateMutation } from "api/rest/quote-request-templates/createQuoteRequestTemplate";
import { Mutation, MutationCreateQuoteRequestTemplateFromQuoteRequestArgs } from "api/types/generated-types";
import { useOnApolloError } from "hooks/useOnApolloError";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { ModeEnum, ModeQuotes } from "types/Mode";
import { useBoolean } from "usehooks-ts";

const CREATE_TEMPLATE_FROM_QUOTE_REQUEST: TypedDocumentNode<
  Pick<Mutation, "createQuoteRequestTemplateFromQuoteRequest">,
  MutationCreateQuoteRequestTemplateFromQuoteRequestArgs
> = gql`
  mutation ($input: CreateQuoteRequestTemplateFromQuoteRequestInput!) {
    createQuoteRequestTemplateFromQuoteRequest(input: $input) {
      id
    }
  }
`;

type HookReturn = {
  dialogOpen: ReturnType<typeof useBoolean>;
  onSubmit: (value: { templateName: string }) => Promise<void>;
  loading: boolean;
};

const useCreateTemplateDialog = ({
  mode,
  quoteRequestId,
}: {
  mode: ModeQuotes;
  quoteRequestId: number;
}): HookReturn => {
  const { t } = useTranslation("shipper", { keyPrefix: "createTemplateDialog" });
  const { onApolloError } = useOnApolloError({ componentName: "useCreateTemplateDialog" });
  const { enqueueSnackbar } = useSnackbar();
  const dialogOpen = useBoolean(false);
  const submitting = useBoolean(false);
  const [__deprecated_createTemplateMutation] = useMutation(CREATE_TEMPLATE_FROM_QUOTE_REQUEST, {
    onCompleted: () => {
      enqueueSnackbar(t("success"), { variant: "success" });
      dialogOpen.setFalse();
    },
    onError: () => {
      onApolloError("createTemplate");
      enqueueSnackbar(t("error"), { variant: "error" });
    },
  });

  const [createQuoteRequestTemplate] = useCreateQuoteRequestTemplateMutation();

  const onSubmit = async ({ templateName }: { templateName: string }) => {
    submitting.setTrue();

    if (mode === ModeEnum.DRAYAGE) {
      try {
        await createQuoteRequestTemplate({
          urlParams: { quoteRequestId: quoteRequestId },
          body: {
            templateName,
          },
        }).unwrap();
        enqueueSnackbar(t("success"), { variant: "success" });
        dialogOpen.setFalse();
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("error"), { variant: "error" });
      }
    } else {
      /** @todo move all quote modes over to use the new quote request templates */
      await __deprecated_createTemplateMutation({
        variables: {
          input: {
            quoteRequestId: String(quoteRequestId),
            name: templateName,
          },
        },
      });
    }

    submitting.setFalse();
  };

  return {
    dialogOpen,
    onSubmit,
    loading: submitting.value,
  };
};

export default useCreateTemplateDialog;
