import { ReactElement } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";

import { Mode, PortexQueryParams, Query } from "../../../api/types/generated-types";
import NotFound404 from "../../../components/errors/NotFound404";
import Loading from "../../../components/Loading";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";
import { isNotAuthorizedError } from "../../../utils/errors/isNotAuthorizedError";
import { isPortexAuthenticationError } from "../../../utils/errors/isPortexAuthenticationError";
import QuoteSubmissionDetails from "../../shipper/components/QuoteSubmissionDetails";
import QuoteSubmissionAIR from "./components/quote-submission-air/components/QuoteSubmissionAIR";
import { ContextProviderQuoteSubmissionAIR } from "./components/quote-submission-air/hooks/useContextQuoteSubmissionAIR";
import QuoteSubmissionFCL from "./components/quote-submission-fcl/components/QuoteSubmissionFCL";
import { ContextProviderQuoteSubmissionFCL } from "./components/quote-submission-fcl/hooks/useContextQuoteSubmissionFCL";
import QuoteSubmissionFTL from "./components/QuoteSubmissionFTL";
import QuoteSubmissionLTL from "./components/QuoteSubmissionLTL";

const GET_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getPublicQuoteRequest">> = gql`
  query {
    getPublicQuoteRequest {
      deadline_respond_at
      guid
      shipper_name
      state
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

const QuoteSubmissionPage = (): ReactElement => {
  const { t } = useTranslation("broker");
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmissionLanding" });
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useUserContext();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  const [tempToken] = useQueryParam(PortexQueryParams.TempToken, StringParam);

  const enqueueExpiredSnack = () => {
    enqueueSnackbar(t("quoteSubmissionPage_message"), {
      variant: "warning",
      preventDuplicate: true,
      persist: true,
    });
  };

  const POLL_INTERVAL_SECONDS = 60; // 1 minute
  const {
    data,
    error,
    loading: publicQuoteRequestLoading,
    refetch,
  } = useQuery(GET_PUBLIC_QUOTE_REQUEST, {
    skip: !tempToken,
    onError: (apolloError) => {
      if (isPortexAuthenticationError(apolloError) || isNotAuthorizedError(apolloError)) {
        enqueueExpiredSnack();
        return;
      }
      onApolloError("getPublicQuoteRequest")(apolloError);
    },
    pollInterval: POLL_INTERVAL_SECONDS * 1000,
  });

  const loading = publicQuoteRequestLoading || isLoading;

  const NotFound = <NotFound404 showAppBar={!enableBrokerAppBar} useMarketingUrl />;

  if (loading) return <Loading showPortexLogo />;
  if (!tempToken || error || (!loading && !data?.getPublicQuoteRequest)) {
    if (tempToken) enqueueExpiredSnack();
    return NotFound;
  }

  switch (data?.getPublicQuoteRequest?.mode) {
    case Mode.Ftl: {
      return <QuoteSubmissionFTL publicQuoteRequest={data.getPublicQuoteRequest} refetchPublicQuoteRequest={refetch} />;
    }
    case Mode.Ltl: {
      return <QuoteSubmissionLTL publicQuoteRequest={data.getPublicQuoteRequest} refetchPublicQuoteRequest={refetch} />;
    }
    case Mode.Fcl: {
      return (
        <ContextProviderQuoteSubmissionFCL
          publicQuoteRequest={data.getPublicQuoteRequest}
          refetchPublicQuoteRequest={refetch}
        >
          <QuoteSubmissionFCL />
        </ContextProviderQuoteSubmissionFCL>
      );
    }
    case Mode.Air: {
      return (
        <ContextProviderQuoteSubmissionAIR
          publicQuoteRequest={data.getPublicQuoteRequest}
          refetchPublicQuoteRequest={refetch}
        >
          <QuoteSubmissionAIR />
        </ContextProviderQuoteSubmissionAIR>
      );
    }
    default: {
      return NotFound;
    }
  }
};

export default QuoteSubmissionPage;
