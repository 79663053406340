import { configureSlices } from "utils/store/configureSlices";

import brokerRequestsIndexFilterSlice from "./brokerRequestsIndexFilterSlice";

const slicesMap = {
  brokerRequestsIndexFilterSlice,
};

export const { useSlices: useBrokerRequestsIndexSlices, useSliceSelector: useBrokerRequestsIndexSliceSelector } =
  configureSlices(slicesMap);
