import { VFC } from "react";

import RequestFlowLayout from "components/RequestFlowLayout";
import { Route, useRouteMatch } from "react-router-dom";

import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import ConfirmationStep from "./ConfirmationStep";
import LaneStep from "./LaneStep";
import PartnersStep from "./PartnersStep";
import QuoteRequestAppBar from "./QuoteRequestAppBar";
import QuoteRequestSidebar from "./QuoteRequestSidebar";
import ReviewStep from "./ReviewStep";
import RoutingStep from "./RoutingStep";
import ShipmentStep from "./ShipmentStep";
import TeamStep from "./TeamStep";

const QuoteRequestPage: VFC = () => {
  const { path } = useRouteMatch();
  return (
    <RequestFlowLayout
      appbar={<Route path={`${path}/:quoteRequestId?`} render={() => <QuoteRequestAppBar />} />}
      sidebar={<QuoteRequestSidebar />}
      sidebarPosition="left"
      sidebarBoxProps={{ bgcolor: "white" }}
    >
      <Route exact path={`${path}/:quoteRequestId?`} render={() => <LaneStep />} />
      <Route exact path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Routing}`} render={() => <RoutingStep />} />
      <Route exact path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Shipment}`} render={() => <ShipmentStep />} />
      <Route exact path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Partners}`} render={() => <PartnersStep />} />
      <Route exact path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Team}`} render={() => <TeamStep />} />
      <Route exact path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Review}`} render={() => <ReviewStep />} />
      <Route
        exact
        path={`${path}/:quoteRequestId/${QuoteRequestPageSteps.Confirmation}`}
        render={() => <ConfirmationStep />}
      />
    </RequestFlowLayout>
  );
};

export default QuoteRequestPage;
