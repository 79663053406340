import { VFC } from "react";

import { Add, Close, FileCopyOutlined } from "@material-ui/icons";
import { Button, Checkbox, NumberInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import AccordionControl from "pages/shipper/pages/request-quote/pages/ltl/components/AccordionControl";
import AccordionView from "pages/shipper/pages/request-quote/pages/ltl/components/AccordionView";
import { useTranslation } from "react-i18next";

import { EquipmentType } from "../api/types";
import { NormalizedCargoGroup } from "../api/types/NormalizedCargoGroup";
import { containerSizesList } from "../const";
import { ContainerType } from "../types";
import { getCargoGroupEquipmentString } from "../utils/getCargoGroupEquipmentString";
import ContainerTypeButtonViewGroup from "./ContainerTypeButtonGroupView";

interface ContainerCargoGroupsView {
  cargoGroups: NormalizedCargoGroup[];

  onChangeCargoGroup: (id: string | number, changes: Partial<NormalizedCargoGroup>) => void;
  onAddCargoGroup: () => void;
  onDuplicate: (cargoGroup: NormalizedCargoGroup) => void;
  onRemoveCargoGroup: (id: string | number) => void;

  renderHandlingUnits: VFC<{ cargoGroupIds: NormalizedCargoGroup["id"][]; parentId: string | number }>;
}

const ContainerCargoGroupsView: VFC<ContainerCargoGroupsView> = (props) => {
  const {
    cargoGroups,
    onChangeCargoGroup,
    onAddCargoGroup,
    onDuplicate,
    onRemoveCargoGroup,
    renderHandlingUnits: HandlingUnitsView,
  } = props;
  const { t } = useTranslation(["common", "shipper"]);

  return (
    <AccordionControl>
      <div className="w-full flex flex-col bg-white p-4">
        {cargoGroups.map((cargoGroup) => (
          <AccordionView
            key={cargoGroup.id}
            summaryContent={
              <div className="w-full flex flex-row items-center justify-between content-center">
                <div className="flex flex-row space-x-2 items-center">
                  <Text size="medium" weight="bold">
                    {cargoGroup.equipment_type
                      ? getCargoGroupEquipmentString(cargoGroup, t)
                      : t("common:containers_count", { count: cargoGroup.count })}
                  </Text>
                  <FileCopyOutlined
                    color="primary"
                    fontSize="small"
                    onClick={(e) => {
                      onDuplicate(cargoGroup);
                      e.stopPropagation();
                    }}
                  />
                </div>
                {cargoGroups.length > 1 && (
                  <Close onClick={() => onRemoveCargoGroup(cargoGroup.id)} className="cursor-pointer" />
                )}
              </div>
            }
            controllerId={cargoGroup.id}
            accordionProps={{ style: { marginBottom: "12px" }, defaultExpanded: true }}
            accordionSummaryProps={{
              style: { backgroundColor: "white" },
              typographyProps: { style: { width: "100%" } },
            }}
            accordionDetailsProps={{ style: { backgroundColor: "white" } }}
          >
            <div className="grid grid-cols-4 gap-x-4 gap-y-5">
              <div className="col-span-2 flex flex-col">
                <Text size="medium" weight="bold" required>
                  {t("common:equipment")}
                </Text>
                <ContainerTypeButtonViewGroup
                  value={cargoGroup.equipment_type as ContainerType}
                  onChange={(equipment_type) => onChangeCargoGroup(cargoGroup.id, { equipment_type })}
                  showError={!cargoGroup.equipment_type}
                />
              </div>
              <div className="flex flex-col col-span-2">
                <Text size="medium" weight="bold" required>
                  {t("common:containerSize")}
                </Text>
                <FormSelectView
                  items={containerSizesList}
                  getItemCopy={(item) => t(`common:trailerSizes.${item}`)}
                  value={cargoGroup.equipment_size}
                  onChange={(equipment_size) => onChangeCargoGroup(cargoGroup.id, { equipment_size })}
                  error={!cargoGroup.equipment_size}
                />
              </div>
              <div className="flex flex-col">
                <Text size="medium" weight="bold">
                  {t("common:totalWeight")}
                </Text>
                <PositiveNumberInput
                  value={cargoGroup.weight_per_count}
                  disableError
                  onChange={(total_weight) => onChangeCargoGroup(cargoGroup.id, { weight_per_count: total_weight })}
                  endIcon={
                    <Text size="small" typographyProps={{ color: "textSecondary" }}>
                      {t("shipper:loadAttributesStep.kilos")}
                    </Text>
                  }
                />
              </div>
              <div className="flex flex-col">
                <Text size="medium" weight="bold">
                  {t("common:totalVolume")}
                </Text>
                <PositiveNumberInput
                  value={cargoGroup.volume_per_count}
                  disableError
                  onChange={(total_volume) => onChangeCargoGroup(cargoGroup.id, { volume_per_count: total_volume })}
                  endIcon={
                    <Text
                      size="small"
                      typographyProps={{ color: "textSecondary", style: { textTransform: "lowercase" } }}
                    >
                      {t("shipper:cbm")}
                    </Text>
                  }
                />
              </div>
              <div className="flex flex-col col-span-2">
                <Text size="medium" weight="bold">
                  {t("common:commodities")}
                </Text>
                <FormStringConcat
                  fullWidth
                  onChange={(value) => onChangeCargoGroup(cargoGroup.id, { commodities: value })}
                  value={cargoGroup.commodities ?? ""}
                  margin="dense"
                  TextInputProps={{ style: { margin: 0 } }}
                />
              </div>
              {cargoGroup.equipment_type === EquipmentType.Reefer && (
                <>
                  <div className="flex flex-col">
                    <Text size="medium" weight="bold">
                      {t("shipper:minTemp")}
                    </Text>
                    <NumberInput
                      disableError
                      value={cargoGroup.min_temp ?? undefined}
                      displayZero
                      onChange={(event) =>
                        onChangeCargoGroup(cargoGroup.id, {
                          min_temp: event.target.value !== "" ? Number(event.target.value) : null,
                        })
                      }
                      placeholder={t("shipper:min")}
                      endIcon={
                        <Text size="small" typographyProps={{ color: "textSecondary" }}>
                          {t("common:°F")}
                        </Text>
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <Text size="medium" weight="bold">
                      {t("shipper:maxTemp")}
                    </Text>
                    <NumberInput
                      disableError
                      value={cargoGroup.max_temp ?? undefined}
                      displayZero
                      onChange={(event) =>
                        onChangeCargoGroup(cargoGroup.id, {
                          max_temp: event.target.value !== "" ? Number(event.target.value) : null,
                        })
                      }
                      placeholder={t("shipper:max")}
                      endIcon={
                        <Text size="small" typographyProps={{ color: "textSecondary" }}>
                          {t("common:°F")}
                        </Text>
                      }
                    />
                  </div>
                  <div className="flex flex-col col-span-2">
                    <Text size="medium" weight="bold">
                      {t("shipper:containersStep.requiresGenset")}
                    </Text>
                    <Checkbox
                      checked={cargoGroup.requires_genset ?? false}
                      onChange={(_event, checked) => onChangeCargoGroup(cargoGroup.id, { requires_genset: checked })}
                      style={{ justifyContent: "flex-start" }}
                    />
                  </div>
                </>
              )}
              <div className="col-span-full">
                <HandlingUnitsView cargoGroupIds={cargoGroup.handling_units ?? []} parentId={cargoGroup.id} />
              </div>
            </div>
          </AccordionView>
        ))}
        <div className="w-full flex flex-row justify-end">
          <Button variant="outlined" color="primary" startIcon={<Add />} onClick={() => onAddCargoGroup()}>
            {t("common:addAnotherContainerType")}
          </Button>
        </div>
      </div>
    </AccordionControl>
  );
};

export default ContainerCargoGroupsView;
